import { useState, useEffect, useRef } from 'react'
import ProductPreview from './ProductPreview'
import { Transition } from '@headlessui/react'



const ProductPreviewAnimated = ({ product, index, onClick }) => {

  const timeout = useRef(null)
  const [isShowing, setIsShowing] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.src = product.imageUrl
    // higher delay for images with higher index number
    const delay = Math.floor(index * 200 + Math.random() * 100)
    
    img.onload = () => {
      timeout.current = setTimeout(() => {
        setIsShowing(true)
      }, delay);
    };

    return () => {
      clearTimeout(timeout.current)
    }
  }, [product.imageUrl, index])
  
  return <div className="w-full shadow-lg
        will-change-transform transition-transform ease-in-out duration-100 
        scale-100 hover:scale-95
        opacity-95 hover:opacity-100">
    <Transition 
      show={isShowing}
      enter="transition ease-out duration-200 transform"
      enterFrom="-translate-x-full"
      enterTo="translate-x-0"
      leave="transition ease-in duration-75 transform"
      leaveFrom="translate-x-0"
      leaveTo="-translate-x-full">
      <ProductPreview product={product} onClick={onClick} />
    </Transition>
  </div>    
}

export default ProductPreviewAnimated