import { ChatAltIcon } from '@heroicons/react/solid'
import { useContext } from 'react'
import EventContext from '../../events/EventContext'
import ManagementContext from '../../management/ManagementContext'
import PreferencesContext from '../../preferences/PreferencesContext'

const ToggleChatButton = () => {

  const { toggleChat } = useContext(PreferencesContext)
  const { isReplay, isReLive, isLive } = useContext(EventContext)
  const { isMod } = useContext(ManagementContext)

  if (isMod) return null
  if (!(isReplay || isReLive || isLive)) return null
  return <ChatAltIcon className="h-6 w-6 drop-shadow lg:hidden" onClick={toggleChat} />

}

export default ToggleChatButton
