import { useCallback, useMemo, useState } from 'react'
import TimedMetadataContext from './TimedMetadataContext'

const TimedMetadataContextProvider = ({ children }) => {

  const [timedMetadata, setTimedMetadataInternal] = useState(null)
  const isInit = useMemo(() => timedMetadata !== null, [timedMetadata]) // if the timedMetadata is null, we haven't initialized yet

  const setTimedMetadata = useCallback((timedMetadata) => {
    setTimedMetadataInternal(timedMetadata)
  }, [])

  const context = useMemo(() => ({
    isInit,
    timedMetadata,
    setTimedMetadata,
  }), [isInit, timedMetadata, setTimedMetadata])


  return <TimedMetadataContext.Provider value={context}>
    {children}
  </TimedMetadataContext.Provider>

}

export default TimedMetadataContextProvider