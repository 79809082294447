import { useContext } from 'react'
import FeatureContext from '../features/FeatureContext'
import ProductBundle from './ProductBundle'
import ProductContext from './ProductContext'

const ProductBundles = () => {

  const { showProductBundles } = useContext(FeatureContext)
  const productContext = useContext(ProductContext)

  if (!showProductBundles) return null

  return <div className="bg-white p-6 md:p-8 lg:px-4 xl:p-10 relative flex flex-col">
    {productContext.productBundles?.map(bundle => {
      return <ProductBundle key={bundle.uid} bundle={bundle} />
    })}
  </div>
}

export default ProductBundles
