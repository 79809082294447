import { useContext, useMemo } from 'react'
import ProductContext from './ProductContext'

const ProductBundleTitle = ({ bundle }) => {

  const { activeBundle } = useContext(ProductContext)
  const isActive = useMemo(() => activeBundle && bundle.uid === activeBundle.uid, [activeBundle, bundle])

  return (
    <span className="relative inline-flex">
      <h3 className="inline-flex items-center font-semibold leading-6 text-sm text-black">{bundle.name}</h3>
      {isActive && <>
        <span className="flex absolute h-3 w-3 top-1.5 left-0 -ml-5">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
        </span>
      </>
      }
    </span>
  )

}

export default ProductBundleTitle