import { getItemSafe, removeItemSafe, setItemSafe } from './localStorageUtil'

const eventDataKey = 'event-'
const userIdKey = 'userId'
const displayNameKey = 'displayName'

/**
 * Tries to get the user info from local storage. Returns undefined if no user info could be found.
 * @returns Returns the retrieved user info or undefined if no user info could be found.
 */
export const getUserOrNull = (eventId) => {
  console.debug('Checking if a username is stored locally...')

  const eventData = JSON.parse(getItemSafe(eventDataKey + eventId) || '{}')
  const userId = eventData[userIdKey] || null
  const displayName = eventData[displayNameKey] || null

  if (userId === null || userId.length === 0 || displayName === null || displayName.length === 0) {
    console.debug('No username found locally.')
    return null
  }

  console.debug('Found a locally stored username.')

  // TODO (GM): Think about if and how to store mod login information.
  return { userId, displayName, isMod: false }
}

/**
 * Saves the passed user info to the local storage.
 * Will override the old user info if old user info exists.
 * @param user The user info to save to the local storage.
 */
export const saveUser = (eventId, user) => {
  console.debug('Adding user info to local storage...')

  const eventData = JSON.parse(getItemSafe(eventDataKey + eventId) || '{}')
  eventData[userIdKey] = user.userId
  eventData[displayNameKey] = user.displayName
  setItemSafe(eventDataKey + eventId, JSON.stringify(eventData))

  console.debug('Successfully added user info to local storage.')
  return user
}

/**
 * Deletes the currently stored user info from the local storage.
 */
export const deleteUserInfoFromLocalStorage = (eventId) => {
  removeItemSafe(eventDataKey + eventId)
}