/**
 * Sends the command to the connection.
 * @param connection The connection to send the command to.
 * @param command The command to send.
 */
export const sendAction = (socket, command) => {
  if (socket?.readyState !== WebSocket.OPEN) return
  const commandJson = JSON.stringify(command)
  socket.send(commandJson)
  console.debug(`Sending message: '${commandJson}'.`)
}

export const sendRequest = async (socket, command) => {
  return new Promise((resolve, reject) => {
    if (socket?.readyState !== WebSocket.OPEN) {
      reject('socket not open!')
      return
    }
    const requestId = command?.data?.requestId
    if (!requestId) {
      reject('requestId is missing!')
      return
    }
    const commandJson = JSON.stringify(command)
    socket.send(commandJson)
    console.debug(`Sending request: '${commandJson}'. `)
    const onMessage = [null]
    const timeout = setTimeout(() => {
      window.removeEventListener('websocket-message', onMessage[0])
      reject()
    }, 5000)
    onMessage[0] = (event) => {
      const message = JSON.parse(event?.detail?.data || '')
      const data = JSON.parse(message?.body)
      const { RequestId } = data
      if (RequestId !== requestId) return
      console.debug('RECEIVED REQUEST MESSAGE', data)
      clearTimeout(timeout)
      window.removeEventListener('websocket-message', onMessage[0])
      resolve(data)
    }
    window.addEventListener('websocket-message', onMessage[0])
  })
}