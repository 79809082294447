import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { initEvent } from './viewerActionSender'

const useEventWebSocket = (eventId, socketUrl) => {

  const timeout = useRef(null)
  const [webSocket, setWebSocket] = useState(null)
  const [lastMessage, setLastMessage] = useState(null)
  const [socketOpen, setSocketOpen] = useState(false)
  const debug = useMemo(() => {
    return process.env.REACT_APP_DEBUG_WEBSOCKET_MESSAGES === 'true'
  }, [])

  const startWebsocket = useCallback((eventId, socketUrl) => {

    if (socketUrl === null) return
    // clear timeout if it exists
    clearTimeout(timeout.current)
  
    let ws = new WebSocket(socketUrl)
    setWebSocket(oldSocket => {
      oldSocket?.close()
      return ws
    })
  
    ws.onopen = () => {
      if (debug) {
        console.debug('WebSocket is now open.')
      }
      setSocketOpen(true)
      initEvent(ws, eventId)
    }
    ws.onclose = () => {
      if (debug) {
        console.debug('WebSocket is now closed.')
      }
      setSocketOpen(false)
      // connection closed, discard old websocket and create a new one in 5s
      // return if timeout is already set
      setWebSocket(null)
      clearTimeout(timeout.current)
      timeout.current = setTimeout(startWebsocket, 5000, eventId, socketUrl)
    }
    ws.onerror = (e) => {
      if (debug) {
        console.error('WebSocket had an error.', e)
      }
      setSocketOpen(false)
      // connection closed, discard old websocket and create a new one in 5s
      // return if timeout is already set
      setWebSocket(null)
      clearTimeout(timeout.current)
      timeout.current = setTimeout(startWebsocket, 5000, eventId, socketUrl)
      window.dispatchEvent(new CustomEvent('websocket-error', e))
    }
    ws.onmessage = (e) => {
      const data =  JSON.parse(e?.data)
      if (debug) {
        const action = data?.action
        const statusCode = data?.statusCode
        try {
          const body = JSON.parse(data?.body)
          console.debug('WebSocket message.', action, statusCode, body)
        } catch(exception) {
          console.debug('WebSocket message.', action, statusCode)
        }
      }
      const requestId = data?.RequestId
      window.dispatchEvent(new CustomEvent('websocket-message', { detail: e }))
      if (!requestId) setLastMessage(e)
    }
  }, [debug])

  useEffect(() => {
    if (!eventId || !socketUrl) return
    startWebsocket(eventId, socketUrl)
  }, [eventId, socketUrl, startWebsocket])

  return { webSocket, lastMessage, socketOpen }

}

export default useEventWebSocket