import { WifiIcon } from '@heroicons/react/solid'
import PosterWrapper from './PosterWrapper'

const Poster = ({ headline, copy, allowVideo = false }) => <PosterWrapper allowVideo={allowVideo}>
  <WifiIcon className='w-12 h-12 mx-auto' />
  <h1 className="mt-4 text-2xl font-extrabold tracking-tight sm:text-3xl lg:text-4xl">{headline}</h1>
  <p className="mt-4 text-base text-gray-100">{copy}</p>
</PosterWrapper>

export default Poster
