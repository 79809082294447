import ProductBundleTitle from './ProductBundleTitle'
import ProductBundleThumbs from './ProductBundleThumbs'
import { useContext, useEffect, useMemo, useRef } from 'react'
import ProductContext from './ProductContext'

const ProductBundle = ({ bundle }) => {

  const bundleRef = useRef(null)
  const { activeBundle } = useContext(ProductContext)
  const isActive = useMemo(() => activeBundle && bundle.uid === activeBundle.uid, [activeBundle, bundle])

  useEffect(() => {
    if (!isActive) return
    bundleRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })  
  }, [isActive])

  return <div ref={bundleRef} className="flex flex-col scroll-m-6">
    <ProductBundleTitle bundle={bundle} />
    <ProductBundleThumbs bundle={bundle} isActive={isActive} />
  </div>

}

export default ProductBundle