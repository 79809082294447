import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useQueryParams from '../../util/useQueryParams'
import EventContext from './EventContext'

const useEventId = () => {

  const { event: queryEventId, options: eventOptions } = useQueryParams({ options: [] })
  const { eventId } = useParams()
  const { setEvent } = useContext(EventContext)

  useEffect(() => {
    setEvent(eventId || queryEventId || process.env.REACT_APP_EVENT_ID, eventOptions)
  }, [eventId, queryEventId, eventOptions, setEvent])

}

export default useEventId
