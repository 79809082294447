import { useContext } from 'react'
import EventContext from '../events/EventContext'
import ToggleChatButton from './buttons/ToggleChatButton'
import ToggleMuteButton from './buttons/ToggleMuteButton'

const UIButtons = () => {

  const { isReplay, isReLive } = useContext(EventContext)

  return <div id="ui-buttons" className="absolute inset-y-0 right-0">
    <div className={`absolute right-0 z-10 px-3 py-4 text-white cursor-pointer bottom-14 ${(isReplay || isReLive) ? '' : 'lg:bottom-0'}`}>
      <div className="absolute right-2 bottom-2 flex flex-col gap-4 p-1">
        <ToggleChatButton />
        <ToggleMuteButton />
      </div>
    </div>
  </div>

}

export default UIButtons
