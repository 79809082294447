import React, { useState, useContext, useEffect, useCallback } from 'react'
import * as actions from '../../util/connection/actions'
import ConnectionHandler from '../../util/connection/ConnectionHandler'
import { saveUser } from '../../util/usernameStorageConnector'
import EventContext from '../events/EventContext'
import SubmitPopup from '../SubmitPopup'
import UserContext from '../userControl/UserContext'
import { sendLoginCommand } from '../../util/connection/viewerActionSender'
import PreferencesContext from '../preferences/PreferencesContext'

/**
 * Returns the sign in JSX.
 * @param param0 The func to call when the username is updated. Use this to signal your outer JSX Element that the username is now set and you can start chatting.
 * @returns Returns the sign in.
 */
export default function SignIn() {

  const [loading, setLoading] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [username, setUsername] = useState('')
  const [error, setError] = useState(null)

  const { setUserShowChat } = useContext(PreferencesContext)
  const { eventId, webSocket, lastMessage, isInit } = useContext(EventContext)
  const [user, setUser] = useContext(UserContext)

  const changeUsername = (username) => {
    setUsername(username)
    setError(null)
  }

  const login = useCallback(() => {
    sendLoginCommand(webSocket, username)
    setLoading(true)
  }, [webSocket, username])

  useEffect(() => {
    ConnectionHandler(lastMessage, actions.ACTION_LOGIN, (user) => {
      setUser(saveUser(eventId, user))
      setShowLogin(false)
      setLoading(false)
      setUserShowChat(true)
    }, (error) => {
      setError(error.errorMessage)
      setLoading(false)
    })
  }, [webSocket, lastMessage, setUser, setUserShowChat, eventId])

  if (user !== null) return null

  return (<>
    {(!user && isInit) && (
      <button onClick={() => setShowLogin(true)} className="bg-white text-black block w-full whitespace-nowrap text-sm px-4 rounded-full focus:border-none focus:outline-none">
          Click to send
      </button>
    )}
    <SubmitPopup
      show={showLogin}
      onCancel={() => setShowLogin(false)}
      onSubmit={login}
      title='Select your username'
      confirmLabel="OK"
      cancelLabel="Cancel"
      disabled={loading}>
      <div className="mt-1 border-b border-gray-300 focus-within:border-black text-sm">
        <input
          name="name"
          id="name"
          type="text"
          className="block w-full px-0 border-0 border-b border-transparent bg-white focus:border-black focus:ring-0 sm:text-sm"
          placeholder="Username"
          autoComplete="off"
          value={username}
          maxLength={20}
          disabled={loading}
          onChange={(e) => changeUsername(e.target.value)}
        />
      </div>
      {error && <span style={{ color: 'red' }}>{error}</span>}
    </SubmitPopup>
  </>
  )
};