import { useContext, useEffect, useMemo, useState } from 'react'
import { ACTION_GET_DELETED_MESSAGES } from '../../util/connection/actions'
import ConnectionHandler from '../../util/connection/ConnectionHandler'
import { getDeletedMessages } from '../../util/connection/viewerActionSender'
import EventContext from '../events/EventContext'
import ChatMessageContext from './ChatMessageContext'

const ChatMessageProvider = ({ children }) => {
  const { webSocket, eventId, lastMessage, isInit } = useContext(EventContext)
  const [messages, setMessages] = useState([])
  const [deletedMessages, setDeletedMessages] = useState([])

  useEffect(() => {
    if (!isInit) return
    getDeletedMessages(webSocket, eventId)
  }, [webSocket, eventId, isInit])

  useEffect(() => {
    ConnectionHandler(lastMessage, ACTION_GET_DELETED_MESSAGES, (data) => {
      const { Items: deletedMessages } = data || {}
      setDeletedMessages(deletedMessages)
    })
  }, [lastMessage])

  const chatMessageContext = useMemo(() => ({
    messages,
    setMessages,
  }), [
    messages,
    setMessages,
  ])

  return <ChatMessageContext.Provider value={chatMessageContext}>
    {children}
  </ChatMessageContext.Provider>
}

export default ChatMessageProvider
