import { useContext, useEffect, useState, useRef, useMemo, useCallback } from 'react'
import * as actions from '../../util/connection/actions'
import EventContext from '../events/EventContext'
import { getMessages, getMessagesAsync } from '../../util/connection/viewerActionSender'
import ConnectionHandler from '../../util/connection/ConnectionHandler'
import ChatMessage from './ChatMessage'
import { addMessages, newestMessage, oldestMessage, updateMessage } from '../../util/messageUtil'
import InfiniteScroll from 'react-infinite-scroll-component'
import TimedMetadataContext from '../events/TimedMetadataContext'
import ManagementContext from '../management/ManagementContext'
import FeatureContext from '../features/FeatureContext'
import PreferencesContext from '../preferences/PreferencesContext'
import StreamContext from '../stream/StreamContext'
import RequestHandler from '../../util/connection/RequestHandler'
import ChatMessageContext from './ChatMessageContext'

const keyFromMessage = (message) => {
  if (!message) return null
  const { eventId, timestamp } = message
  return { EventId: { S: eventId }, Timestamp: { S: timestamp } }
}

const ChatMessages = ({ autoScroll, immediate = false }) => {

  const { messages, setMessages } = useContext(ChatMessageContext)
  const { showChat } = useContext(FeatureContext)
  const { userShowChat } = useContext(PreferencesContext)
  const { userHasSkipped, setUserHasSkipped, videoHasEnded } = useContext(StreamContext)
  const [lastMessagesLoaded, setLastMessagesLoaded] = useState(false)
  const [lastMessageId, setLastMessageId] = useState([])
  const { authToken, isMod } = useContext(ManagementContext)
  const { webSocket, socketOpen, lastMessage, isInit, isLive, isStarted } = useContext(EventContext)
  const messagesEndRef = useRef(null)
  const hasMore = useMemo(() => true, [])
  const { timedMetadata } = useContext(TimedMetadataContext)

  const setMessagesCallback = useCallback((messageData) => {
    setMessages(messages => {
      const index = messages?.findIndex(message => message.messageId === messageData.messageId)
      if (index >= 0) return messages // message is already existing in the messages array
      return messages.concat([messageData])
    })
  }, [setMessages])

  const sortedMessages = useMemo(() => {
    const sorted = [...messages]
    sorted.forEach(message => message.date = new Date(message.timestamp))
    return sorted.sort((a, b) => b.date - a.date)
  }, [messages])

  const messagesById = useMemo(() => {
    return messages.reduce((map, message) => {
      const { messageId } = message
      map[messageId] = message
      return map
    }, {})
  }, [messages])

  const scrollToBottom = () => {
    if (!autoScroll) return;
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const mayLoadMessages = useMemo(() => {
    return isInit && socketOpen
  }, [isInit, socketOpen])

  const getLastKey = useCallback(() => {
    if (messages?.length === 0) return null
    return keyFromMessage(newestMessage(messages))
  }, [messages])

  const fetchMessages = useCallback(() => {
    console.debug('load more messages...')
    const key = keyFromMessage(oldestMessage(messages))
    if (!key) return
    if (immediate && mayLoadMessages) getMessages(webSocket, { key, ScanIndexForward: false })
    if (!immediate && mayLoadMessages && showChat) getMessages(webSocket, { key, ScanIndexForward: false })
  }, [immediate, webSocket, mayLoadMessages, showChat, messages])

  const fetchSkipped = useCallback((key) => {
    getMessages(webSocket, {
      requestId: 'skippedMessages',
      ScanIndexForward: false,
      key,
    })
  }, [webSocket])

  const fetchAfterEventMessages = useCallback(async () => {
    let key = getLastKey()
    if (!key) return
    let newMessages = []
    let tempMessages = null
    while (tempMessages === null || tempMessages?.length > 0) {
      const receivedMessages = await getMessagesAsync(webSocket, {
        key,
        requestId: 'afterEventMessages',
        ScanIndexForward: true,
      })
      if (!receivedMessages?.length) break
      tempMessages = receivedMessages
      newMessages = [...newMessages, ...receivedMessages]
      key = keyFromMessage(newestMessage(receivedMessages))
    }
    setLastMessagesLoaded(true)
    if (!newMessages?.length) return
    setMessages(m => addMessages(m, newMessages))
  }, [getLastKey, webSocket, setMessages])

  useEffect(() => {
    if (immediate || !mayLoadMessages) return
    if (!videoHasEnded) return
    if (lastMessagesLoaded) return
    fetchAfterEventMessages()
  }, [fetchAfterEventMessages, immediate, lastMessagesLoaded, mayLoadMessages, videoHasEnded, webSocket])

  /**
   * immediate mode (loading directly when initialized for moderation team)
   **/
  useEffect(() => {
    if (immediate && mayLoadMessages) getMessages(webSocket)
    // non-immediate mode (loading when replay is active and / or playing = true)
    // if (!immediate && mayLoadMessages && showChat) getMessages(webSocket)
  }, [immediate, mayLoadMessages, webSocket, showChat])

  useEffect(() => {
    if (immediate) return
    if (!mayLoadMessages) return
    if (isStarted && isLive) getMessages(webSocket)
  }, [immediate, mayLoadMessages, webSocket, isLive, isStarted])

  useEffect(scrollToBottom, [autoScroll, lastMessageId]);

  useEffect(() => {
    if (sortedMessages.length > 0) setLastMessageId(sortedMessages[0])
  }, [sortedMessages])

  useEffect(() => {
    const newMessages = timedMetadata?.messages
    if (!newMessages?.length) return
    setMessages(messages => addMessages(messages, newMessages))
    const key = keyFromMessage(oldestMessage(newMessages))
    if (key && userHasSkipped) {
      setUserHasSkipped(false)
      fetchSkipped(key)
    }
  }, [fetchSkipped, setUserHasSkipped, timedMetadata, userHasSkipped, setMessages])

  useEffect(() => {
    if (!socketOpen) return
    RequestHandler(lastMessage, 'skippedMessages', (data) => {
      const { Items: skippedMessages } = data || {}
      if (!skippedMessages?.length) return
      const oldestSkipped = oldestMessage(skippedMessages)
      console.debug('receive skipped messages', skippedMessages)
      setMessages(messages => {
        if (!messages.some(message => message.messageId === oldestSkipped.messageId)) {
          console.debug('request more skipped...')
          fetchSkipped(keyFromMessage(oldestSkipped))
        }
        return addMessages(messages, skippedMessages)
      })
    })
    ConnectionHandler(lastMessage, actions.ACTION_GET_MESSAGES, (data) => {
      try {
        const { Items: newMessages } = data || {}
        setMessages(messages => addMessages(messages, newMessages))
      } catch (e) {
        console.error(e)
      }
    })
    ConnectionHandler(lastMessage, actions.ACTION_PUT_MESSAGE, setMessagesCallback)
    ConnectionHandler(lastMessage, actions.ACTION_NEW_MESSAGE_RECEIVED, setMessagesCallback)
    ConnectionHandler(lastMessage, actions.ACTION_UPDATED_MESSAGE_RECEIVED, (updatedMessage) => {
      setMessages(messages => updateMessage(messages, updatedMessage))
    })
    if (authToken) ConnectionHandler(lastMessage, actions.ACTION_PUT_MOD_MESSAGE, setMessagesCallback)
  }, [authToken, fetchSkipped, lastMessage, socketOpen, setMessagesCallback, setMessages])

  // TODO: Add the display of error messages if the sending failed?
  // TODO implement working inverse infinite scroll:
  // https://codesandbox.io/s/youthful-kapitsa-nlv1j?file=/src/index.js:984-994
  return (<div id="scrollable-chat" className="h-full flex flex-col-reverse overflow-y-scroll flex-grow scrollbar-hide gradient-mask-t-70 lg:gradient-mask-t-90 pt-6 pb-2 relative">
    <InfiniteScroll
      dataLength={sortedMessages?.length}
      next={() => fetchMessages()}
      inverse={true}
      hasMore={hasMore}
      scrollableTarget="scrollable-chat"
      className={`flex flex-col-reverse gap-1 w-2/3 lg:w-full max-w-lg min-h-full pt-12 ${((userShowChat && showChat) || isMod) ? '': 'hidden'}`}
    >
      <div ref={messagesEndRef} />
      {sortedMessages?.map((message) => {
        const { messageId, parentId } = message
        const parentMessage = messagesById[parentId] || null
        return <ChatMessage
          key={messageId}
          message={message}
          parentMessage={parentMessage}
        />
      })}
    </InfiniteScroll>
  </div>)
}

export default ChatMessages
