import { useCallback, useContext } from 'react'
import { sendReactionCommand } from '../../util/connection/viewerActionSender'
import EventContext from '../events/EventContext'
import ReactionContext from './ReactionContext'
import emoticons from '../../data/emoticons'
import ManagementContext from '../management/ManagementContext'
import FeatureContext from '../features/FeatureContext'

const ReactionSelect = () => {

  const { showReactionBar } = useContext(FeatureContext)
  const { authToken } = useContext(ManagementContext)

  const onClickReaction = useCallback((socket, emojiIndex) => {
    sendReactionCommand(socket, emojiIndex)
  }, [])

  if (!showReactionBar) return null
  if (!!authToken) return null

  return <EventContext.Consumer>
    {({ webSocket }) => (<div className="flex flex-row select-none snap-normal snap-start pr-2">
      <ReactionContext.Consumer>
        {(reactionContext) => <>{Object.entries(emoticons).map((emojiData, index) => {
          return <div key={index} onClick={() => {
            reactionContext?.addReaction(emojiData[1])
            onClickReaction(webSocket, emojiData[0])
          }} className="w-10 h-12 cursor-pointer inline-flex items-center justify-center scale-100 hover:scale-125 transition-transform ease-in-out duration-200">
            <span className="text-2xl select-none leading-none">
              {emojiData[1].unicode}
            </span>
          </div>
        })}
        </>}
      </ReactionContext.Consumer>
    </div>)}
  </EventContext.Consumer >
  
}

export default ReactionSelect