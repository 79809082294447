import { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import * as eventStatus from '../../util/eventStatus'
import EventContext from '../events/EventContext'
import ManagementContext from './ManagementContext'
import { putEventStatus } from '../../util/connection/viewerActionSender'

const EVENT_STATUS_MAP = {
  [eventStatus.EVENT_NOT_STARTED]: 'Not started',
  [eventStatus.EVENT_HAS_STARTED]: 'LIVE !!',
  [eventStatus.EVENT_IS_PROCESSING]: 'Currently processing',
  [eventStatus.EVENT_REPLAY_AVAILABLE]: 'Replay',
  [eventStatus.EVENT_IS_RELIVE]: 'RE-LIVE',
}

const EventStatusSelect = () => {

  const { authToken } = useContext(ManagementContext)
  const { webSocket, eventId, status } = useContext(EventContext)
  const [eventStatus, setEventStatus] = useState(null)

  const updateEvent = useCallback((statusId) => {
    putEventStatus(webSocket, authToken, eventId, statusId)
  }, [webSocket, authToken, eventId])

  const changeStatus = useCallback((statusId) => {
    setEventStatus(statusId)
    updateEvent(statusId)
  }, [updateEvent])

  useEffect(() => {
    setEventStatus(status)
  }, [status])

  if (eventStatus === null) return null

  return (<div className="w-full pointer-events-auto">
    <Listbox value={eventStatus} onChange={(s => changeStatus(parseInt(s)))}>
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
          <span className="block truncate">{EVENT_STATUS_MAP[eventStatus]}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {Object.entries(EVENT_STATUS_MAP).map((entry) => (
              <Listbox.Option
                key={entry[0]}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-green-100 text-green-900' : 'text-gray-900'
                  }`
                }
                value={entry[0]}>
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${selected ? 'font-medium' : 'font-normal' }`}
                    >
                      {entry[1]}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-green-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  </div>)
}

export default EventStatusSelect
