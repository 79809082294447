import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/ext-language_tools'
import EventContext from '../events/EventContext'
import ManagementContext from './ManagementContext'
import { putEventMetadata } from '../../util/connection/viewerActionSender'

const EditMetaData = () => {

  const { authToken } = useContext(ManagementContext)
  const { eventId, metadata, webSocket } = useContext(EventContext)
  const [editorData, setEditorData] = useState(null)

  const onChange = useCallback((data) => {
    setEditorData(data)
  }, [])

  const onValidate = useCallback(() => {}, [])

  const saveData = useCallback((data) => {
    console.log('save meta data', data, webSocket, authToken, eventId)
    putEventMetadata(webSocket, authToken, eventId, JSON.stringify(data))
  },[authToken, eventId, webSocket])

  const save = useCallback((editor) => {
    console.log('save meta data annotations', editor.session.getAnnotations())
    if (editor.session.getAnnotations().length > 0) {
      // TODO display error message
      return
    }
    const data = JSON.parse(editor.session.getValue())
    saveData(data)
  }, [saveData])

  const onClickSave = useCallback(() => {
    try {
      const data = JSON.parse(editorData)
      saveData(data)
    } catch (e) {
      console.error(e)
    }
  }, [saveData, editorData])

  const commands = useMemo(() => {
    return [{
      name: 'save',
      bindKey: {
        lin: 'Ctrl-S',
        win: 'Ctrl-S',
        mac: 'Cmd-S',
      },
      exec: save
    }]
  }, [save])

  useEffect(() => {
    console.log('NEW METADATA', metadata)
    if (metadata !== null) return
    setEditorData(JSON.stringify(metadata, null, 2))
  }, [metadata])

  useEffect(() => {
    if (editorData !== null || metadata === null) return
    setEditorData(JSON.stringify(metadata, null, 2))
  }, [editorData, metadata])

  if (editorData === null) return null

  return <div className="w-full flex flex-col gap-2 p-2">
    <AceEditor
      mode="json"
      theme="monokai"
      onChange={onChange}
      onValidate={onValidate}
      name="meta-data-editor"
      width="100%"
      className="h-full-max"
      value={editorData}
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
      }}
      tabSize={2}
      fontSize={10}
      wrapEnabled={true}
      commands={commands}
    />
    <button onClick={onClickSave} className="w-full bg-indigo-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Metadata</button>
  </div>

}

export default EditMetaData
