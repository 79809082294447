import { useContext } from 'react'
import EventPage from '../EventPage'
import EventContext from '../events/EventContext'
import ProductModal from '../products/ProductModal'
import ProductPreviewContainer from '../products/ProductPreviewContainer'
import ReactionOverlay from '../reactions/ReactionOverlay'
import VideoWrapper from '../stream/videoPlayer/VideoWrapper'
import UIButtons from '../ui/UIButtons'

const ManagementStream = () => {

  const { isPlaying } = useContext(EventContext)

  return <VideoWrapper>
    <EventPage />
    <UIButtons />
    {isPlaying && <>
      <ReactionOverlay />
      <ProductPreviewContainer />
      <ProductModal />
    </>}
  </VideoWrapper>

}

export default ManagementStream
