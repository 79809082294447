import { useCallback, useContext, useState } from 'react'
import { putS3Stream } from '../../util/connection/viewerActionSender'
import EventContext from '../events/EventContext'
import ManagementContext from './ManagementContext'

const EventStartStreamButton = () => {

  const { webSocket } = useContext(EventContext)
  const { authToken } = useContext(ManagementContext)
  const [streamfileKey, setStreamfileKey] = useState('')

  const onSubmit = useCallback((e) => {
    e.preventDefault()
    putS3Stream(webSocket, authToken, streamfileKey)
  }, [streamfileKey, webSocket, authToken])

  return <form onSubmit={onSubmit}>
    <div className='py-1'>
      <input placeholder="S3 Video Path" className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm' value={streamfileKey || ''} onChange={(e) => setStreamfileKey(e.target.value)} />
    </div>
    <button type="submit" className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Start Stream Now</button>
  </form>

}

export default EventStartStreamButton
