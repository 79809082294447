import { Outlet } from 'react-router-dom'
import ManagementContextProvider from './ManagementContextProvider'

const ModOutlet = () => {
  return <ManagementContextProvider>
    <Outlet />
  </ManagementContextProvider>
}

export default ModOutlet
