import { useCallback, useContext } from 'react'
import EventContext from '../events/EventContext'
import ManagementContext from '../management/ManagementContext'
import { UserAddIcon } from '@heroicons/react/solid'
import { sendUnbanUserAction } from '../../util/connection/viewerActionSender'

const ModerationButtons = ({ user }) => {

  const { webSocket, eventId } = useContext(EventContext)
  const { authToken } = useContext(ManagementContext)

  const unbanUser = useCallback(() => {
    const { id: userId } = user
    sendUnbanUserAction(webSocket, authToken, userId, eventId)
  }, [webSocket, authToken, user, eventId])

  return <div className="flex flex-row gap-2 text-red-600">
    {/* {!isDeleted && <TrashIcon className="h-4 w-4 mx-auto cursor-pointer hover:text-white" onClick={deleteMessage} />}
    {isDeleted && <RefreshIcon className="h-4 w-4 mx-auto cursor-pointer hover:text-white" onClick={restoreMessage} />}
    {canBanUser && <UserRemoveIcon className="h-4 w-4 mx-auto cursor-pointer hover:text-white" onClick={banUser} />} */}
    <UserAddIcon className="h-4 w-4 mx-auto cursor-pointer hover:text-red-500" onClick={unbanUser} />
  </div>

}

const UserListItem = ({ user }) => {

  const { authToken } = useContext(ManagementContext)
  const { displayName } = user

  return <div className={'w-full inline-flex flex-shrink px-2 gap-1 items-center bg-red-900'}>
    {authToken && <ModerationButtons user={user} />}
    <div className={'max-w-full '}>
      <p className={'text-ellipsis overflow-hidden flex-1 px-2 py-1 space-x-1 text-xs sm:text-sm leading-tight '}>
        <span className="font-medium pr-1.5 text-red-300">{displayName}</span>
      </p>
    </div>
  </div>

}

export default UserListItem
