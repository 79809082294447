import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { sendItems } from '../../util/connection/viewerActionSender'
import EventContext from '../events/EventContext'
import ProductContext from '../products/ProductContext'
import ProductPreview from '../products/ProductPreview'
import ManagementContext from './ManagementContext'
import ProductBundleTitle from '../products/ProductBundleTitle'
import FeatureContext from '../features/FeatureContext'

const ManagableProductBundle = ({ bundle }) => {

  const bundleRef = useRef(null)
  const { showBundleActivateButtons } = useContext(FeatureContext)
  const { activeBundle } = useContext(ProductContext)
  const { authToken } = useContext(ManagementContext)
  const { webSocket } = useContext(EventContext)
  const [isPending, setIsPending] = useState(false)
  const [isPendingActive, setIsPendingActive] = useState(false)
  const isActive = useMemo(() => activeBundle && bundle.uid === activeBundle.uid, [activeBundle, bundle])

  const onToggle = useCallback(() => {
    setIsPending(true)
    setIsPendingActive(!isActive)
    sendItems(webSocket, authToken, isActive ? null : bundle.uid)
  }, [bundle, webSocket, authToken, isActive])

  useEffect(() => {
    setIsPending(false)
  }, [activeBundle])

  useEffect(() => {
    if (!isActive) return
    bundleRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [isActive])

  const toggleLabel = useMemo(() => {
    if (isPending) return isPendingActive ? 'Activating...' : 'Deactivating...'
    return isActive ? 'Deactivate' : 'Activate'
  }, [isActive, isPending, isPendingActive])
  const buttonColor = useMemo(() => {
    if (isPending) return isPendingActive ? 'bg-green-300' : 'bg-rose-400'
    return isActive ? 'bg-green-500 hover:bg-rose-600' : 'bg-stone-300 hover:bg-green-600'
  }, [isActive, isPending, isPendingActive])

  return <div className="bg-white shadow sm:rounded-sm">
    <div className="px-4 py-5 sm:p-6">
      <div className="sm:flex sm:items-start sm:justify-between">
        <div ref={bundleRef}>
          <ProductBundleTitle bundle={bundle} />
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <div className="flex flex-row gap-2">
              {bundle.products.map(product => {
                return <ProductPreview key={product.uid} product={product} />
              })}
            </div>
          </div>
        </div>
        {showBundleActivateButtons && <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
          <button onClick={onToggle} disabled={isPending} type="button" className={'inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white focus:outline-none sm:text-sm ' + buttonColor}>{toggleLabel}</button>
        </div>
        }
      </div>
    </div>
  </div>


}

export default ManagableProductBundle