const emoticons = {
  1: { unicode: '❤️' },
  2: { unicode: '👍' },
  3: { unicode: '🦄' },
  4: { unicode: '🙈' },
  // 5: { unicode: '🤔' },
  6: { unicode: '😂' },
  // 7: { unicode: '😡' }
}

export default emoticons
