const localStorageAvailable = () => {
  var test = '[storage-availability]'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

const fallbackStorage = {}

export const setItemSafe = (key, value) => {
  if (localStorageAvailable()) {
    localStorage.setItem(key, value)
  } else {
    fallbackStorage[key] = value
  }
}

export const getItemSafe = (key) => {
  if (localStorageAvailable()) {
    return localStorage.getItem(key)
  } else {
    return fallbackStorage[key]
  }
}

export const removeItemSafe = (key) => {
  if (localStorageAvailable()) {
    localStorage.removeItem(key)
  } else {
    delete fallbackStorage[key]
  }
}