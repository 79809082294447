import { useCallback, useEffect, useMemo, useState } from 'react'
import useEventWebSocket from '../../util/connection/useEventWebSocket'
import EventContext from './EventContext'
import * as config from '../../config'
import * as actions from '../../util/connection/actions'
import * as eventStatus from '../../util/eventStatus'
import ConnectionHandler from '../../util/connection/ConnectionHandler'
import { useNavigate } from 'react-router-dom'
import { getRecordings } from '../../util/connection/viewerActionSender'

const EventContextProvider = ({ children }) => {

  const navigate = useNavigate()
  const [eventId, setEventId] = useState(null)
  const [eventOptions, setEventOptions] = useState([])
  const { webSocket, lastMessage, socketOpen } = useEventWebSocket(eventId, process.env.REACT_APP_FRONTEND_WEBSOCKET || config.FRONTEND_WEBSOCKET)

  const [isInit, setIsInit] = useState(false)
  const [isLive, setIsLive] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  const [recordingUrl, setRecordingUrl] = useState(null)
  const [recordingThumbnail, setRecordingThumbnail] = useState(null)
  const [status, setStatus] = useState(null)
  const [eventData, setEventData] = useState({})
  const [metadata, setMetadata] = useState(null)
  const onIsPlaying = useCallback((isPlaying) => {
    setIsPlaying(isPlaying)
  }, [])

  const moderatorName = useMemo(() => eventData?.moderatorName || null, [eventData])
    
  const isUnkown = useMemo(() => status === eventStatus.EVENT_UNKNOWN, [status])
  const isNotStarted = useMemo(() => status === eventStatus.EVENT_NOT_STARTED, [status])
  const isStarted = useMemo(() => status === eventStatus.EVENT_HAS_STARTED, [status])
  const isProcessing = useMemo(() => status === eventStatus.EVENT_IS_PROCESSING, [status])
  const isReplay = useMemo(() => status === eventStatus.EVENT_REPLAY_AVAILABLE, [status])
  const isReLive = useMemo(() => status === eventStatus.EVENT_IS_RELIVE, [status])

  const posterUrl = useMemo(() => metadata?.poster || null, [metadata])
  const previewVideoUrl = useMemo(() => metadata?.previewVideoUrl || null, [metadata])
  const chatIsInStream = useMemo(() => {
    if (!metadata) return null
    if (!('chatIsInStream' in metadata)) return true
    return metadata?.chatIsInStream
  }, [metadata])

  const [streamUrl, setStreamUrl] = useState(null)

  const setEvent = useCallback((newEventId, options) => {
    if (eventId === newEventId) return
    setEventId(newEventId)
    setEventOptions(options)
  }, [eventId])

  useEffect(() => {
    const { metadata, isLive, playbackUrl, statusId } = eventData || {}

    setIsLive(isLive)
    setStatus(statusId)
    setMetadata(metadata)
   
    if (isLive && isStarted) {
      setStreamUrl(playbackUrl)
    }
  
  }, [eventData, isStarted, isReplay, isReLive])

  useEffect(() => {
    getRecordings(webSocket, eventId)
  }, [webSocket, isReplay, eventId, isReLive])

  useEffect(() => {
    ConnectionHandler(lastMessage, actions.ACTION_INIT, (data) => {
      setEventData(eventData => ({ ...eventData, ...data }))
      setIsInit(true)
    }, () => navigate('/not-found'))
  }, [webSocket, lastMessage, navigate])

  useEffect(() => {
    ConnectionHandler(lastMessage, actions.ACTION_UPDATED_EVENT, (data) => {
      setEventData(eventData => ({ ...eventData, ...data }))
    })
  }, [webSocket, lastMessage])
  
  useEffect(() => {
    if (!(isReplay || isReLive)) return // don't fetch recordings when event is not in replay mode
    ConnectionHandler(lastMessage, actions.ACTION_GET_RECORDINGS, (recordingsData) => {
      const recording = recordingsData.Items[0] || {}
      const { playbackUrl, thumbnail } = recording
      setRecordingUrl(playbackUrl)
      setRecordingThumbnail(thumbnail)
    })
  }, [webSocket, lastMessage, isReplay, isReLive])

  const eventContext = useMemo(() => ({
    chatIsInStream,
    eventId,
    eventOptions,
    eventData,
    isInit,
    isLive,
    isNotStarted,
    isPlaying,
    isProcessing,
    isReplay,
    isReLive,
    isStarted,
    isUnkown,
    lastMessage,
    metadata,
    moderatorName,
    onIsPlaying,
    posterUrl,
    previewVideoUrl,
    recordingThumbnail,
    recordingUrl,
    setEvent,
    socketOpen,
    status,
    streamUrl,
    webSocket,
  }), [
    chatIsInStream,
    eventId,
    eventOptions,
    eventData,
    isInit,
    isLive,
    isNotStarted,
    isPlaying,
    isProcessing,
    isReplay,
    isReLive,
    isStarted,
    isUnkown,
    lastMessage,
    metadata,
    moderatorName,
    onIsPlaying,
    posterUrl,
    previewVideoUrl,
    recordingThumbnail,
    recordingUrl,
    setEvent,
    socketOpen,
    status,
    streamUrl,
    webSocket,
  ])

  return <EventContext.Provider value={eventContext}>
    {children}
  </EventContext.Provider>
}

export default EventContextProvider
