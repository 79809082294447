import { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PencilAltIcon, CubeIcon } from '@heroicons/react/solid'

import ManagementLogin from './ManagementLogin'
import Chat from '../chat/Chat'
import BundleManager from './BundleManager'
import ManagementContext from './ManagementContext'
import BannedUsers from '../userControl/BannedUsers'
import EventControl from './EventControl'
import ManagementStream from './ManagementStream'
import useQueryParams from '../../util/useQueryParams'
import EventContext from '../events/EventContext'
import ManagementEventId from './ManagementEventId'
import EditMetaData from './EditMetaData'

const Management = () => {

  const { options } = useQueryParams({ options: [] })
  const { authToken } = useContext(ManagementContext)
  const { setEvent } = useContext(EventContext)
  const { eventId } = useParams()
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    if (!eventId) return
    setEvent(eventId, options)
  }, [eventId, options, setEvent])

  const toggleEditMode = useCallback(() => {
    setEditMode(!editMode)
  }, [editMode])

  if (!authToken) return <ManagementLogin />
  if (!eventId) return <ManagementEventId />

  return <div className="flex flex-grow">
    <div className='flex flex-grow h-full relative bg-white '>
      <div className="absolute inset-y-0 right-0 left-auto flex flex-col overflow-y-auto scrollbar-hide max-w-xl w-full">
        <div className="flex flex-col gap-1 w-full">
          {editMode && <>
            <CubeIcon className="h-4 w-4 mx-auto cursor-pointer text-black" onClick={toggleEditMode} />
            <EditMetaData />
          </>}
          {!editMode && <>
            <PencilAltIcon className="h-4 w-4 mx-auto cursor-pointer text-black" onClick={toggleEditMode} />
            <BundleManager />
            <EventControl />
          </>}
        </div>
      </div>
    </div>
    <ManagementStream />
    <div className="flex flex-grow h-full relative">
      <div id="chat" className="absolute inset-0 flex flex-col bg-white">
        <Chat autoScrollOption="true" immediate="true" />
        <BannedUsers />
      </div>
    </div>
  </div>

}

export default Management
