import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function SubmitPopup({ children, disabled = false, title, confirmLabel = '', cancelLabel = '', show, onSubmit = () => { }, onCancel = () => { } }) {
  const onFormSubmit = e => {
    e.preventDefault()
    onSubmit()
    // send to server with e.g. `window.fetch`
  }
  return (<Transition.Root show={show} as={Fragment}>
    <Dialog
      as="div"
      className="fixed z-10 inset-0 overflow-y-auto"
      onClose={onCancel}
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="inline-block align-bottom bg-white rounded-lg px-12 py-8 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <form onSubmit={onFormSubmit}>
              <div>
                <div className="mt-3 text-center">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {title}
                  </Dialog.Title>
                  <div className="mt-6 mb-10">
                    {children}
                  </div>
                </div>
              </div>
              <div className="mt-5 space-y-3">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center border border-transparent px-4 py-2 bg-black text-base font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-transparent"
                  disabled={disabled}>
                  {confirmLabel}
                </button>
                <button
                  type="button"
                  className="w-full inline-flex justify-center border border-black px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-900 hover:text-white focus:outline-none focus:bg-gray-900 focus:text-white focus:ring-transparent"
                  onClick={onCancel}
                  disabled={disabled}>
                  {cancelLabel}
                </button>
              </div>
            </form>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>)
};