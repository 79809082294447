const ManagementForm = ({ children, title, onSubmit }) => {

  return <div className="bg-gray-50 w-full h-full min-h-screen">
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-20 w-auto" src="https://live.covr.app/favicon.svg" alt="COVR" />
        <h2 className="mt-6 text-center text-3xl font-semibold text-gray-900">{title}</h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={onSubmit}>
            {children}
          </form>
        </div>
      </div>
    </div>
  </div>

}

export default ManagementForm
