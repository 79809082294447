import { useContext } from 'react'
import ProductContext from './ProductContext'
import { BottomSheet } from 'react-spring-bottom-sheet'
import ProductBundles from './ProductBundles'
import FeatureContext from '../features/FeatureContext'

const ProductBundlesBottomSheet = () => {

  const { showProductBundles } = useContext(FeatureContext)
  const productContext = useContext(ProductContext)
  const show = productContext.showProductBundles

  const onClose = () => {
    productContext.onCloseProductBundles()
  }

  if (!showProductBundles) return null

  return <BottomSheet
    className='shadow-xl'
    open={show}
    onDismiss={onClose}
    defaultSnap={({ maxHeight }) => maxHeight / 2}
    snapPoints={({ maxHeight }) => [maxHeight / 4, maxHeight / 2]}
    expandOnContentDrag={true}
  >
    <div id="product-bundles" className="rounded-t-xl relative">
      <ProductBundles />
    </div>
  </BottomSheet>

}

export default ProductBundlesBottomSheet
