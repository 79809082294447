import { useCallback, useContext, useEffect, useState } from 'react'
import PreferencesContext from '../../preferences/PreferencesContext'
import VideoJS from './VideoJS'

const IntroPlayer = ({ src }) => {

  const { setUserVideoMuted, userVideoMuted } = useContext(PreferencesContext)
  const [videoJSPlayer, setVideoJSPlayer] = useState(null)

  const onReady = useCallback((videoJSPlayer) => {
    videoJSPlayer.play()
    videoJSPlayer.muted(false)
    setVideoJSPlayer(videoJSPlayer)
  }, [])

  useEffect(() => {
    if (videoJSPlayer === null) return
    videoJSPlayer.muted(userVideoMuted)

    const volumeListener = () => {
      setUserVideoMuted(videoJSPlayer.muted())
    }

    videoJSPlayer.on('volumechange', volumeListener)
    return () => {
      videoJSPlayer.off('volumechange', volumeListener)
    }
  }, [setUserVideoMuted, userVideoMuted, videoJSPlayer])

  return <div className="h-full w-full absolute">
    <VideoJS
      onReady={onReady}
      src={src}
      loop
      autoPlay
      playsInline
    />
  </div>

}

export default IntroPlayer
