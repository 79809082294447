import { useCallback, useContext, useEffect, useState } from 'react'
import { putEventModeratorName } from '../../util/connection/viewerActionSender'
import EventContext from '../events/EventContext'
import ManagementContext from './ManagementContext'

const EventModeratorNameInput = () => {

  const { webSocket, eventId, moderatorName } = useContext(EventContext)
  const { authToken } = useContext(ManagementContext)
  const [editModeratorName, setEditModeratorName] = useState(moderatorName)

  const onSubmit = useCallback((e) => {
    e.preventDefault()
    console.log('submit', editModeratorName)
    putEventModeratorName(webSocket, authToken, eventId, editModeratorName)
  }, [editModeratorName, webSocket, authToken, eventId])

  useEffect(() => {
    setEditModeratorName(moderatorName)
  }, [moderatorName])

  return <form onSubmit={onSubmit}>
    <div className='py-1'>
      <input className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm' value={editModeratorName || ''} onChange={(e) => setEditModeratorName(e.target.value)} />
    </div>
    <button type="submit" className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Save</button>

  </form>

}

export default EventModeratorNameInput
