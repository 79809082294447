
import { useCallback, useEffect, useRef } from 'react'
import { registerIVSTech } from 'amazon-ivs-player'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

const videoJsOptions = {
  techOrder: ['AmazonIVS'],
  autoplay: false,
  aspectRatio: '9:16',
  responsive: false,
  fluid: false,
  inactivityTimeout: 0,
  controlBar: {
    audioTrackButton: false,
    captionsButton: false,
    chaptersButton: false,
    currentTimeDisplay: false,
    customControlSpacer: false,
    descriptionsButton: false,
    durationDisplay: false,
    fullscreenToggle: false, // Removes the full screen option
    liveDisplay: false,
    pictureInPictureToggle: false,
    playToggle: true,
    playbackRateMenuButton: false,
    progressControl: { seekBar: true },
    remainingTimeDisplay: {
      displayNegative: false
    },
    seekToLive: false,
    subsCapsButton: false,
    subtitlesButton: false,
    timeDivider: false,
    volumePanel: false,
    children: [
      {
        name: 'remainingTimeDisplay'
      },
      {
        name: 'progressControl'
      },
      {
        name: 'playToggle'
      },
    ]
  }
}

export const VideoJS = ({ onReady, src, preload = false, ...rest }) => {
  const videoRef = useRef(null)
  const playerRef = useRef(null)

  const onPlayerReady = useCallback(() => {
    if (playerRef.current === null) return
    playerRef.current.src(src)

    onReady && onReady(playerRef.current)
  }, [onReady, src])

  const initPlayer = useCallback(() => {
    if (playerRef.current !== null) return

    registerIVSTech(videojs, {
      wasmWorker: '/amazon-ivs-wasmworker.min.js',
      wasmBinary: '/amazon-ivs-wasmworker.min.wasm',
    })

    // merge options
    const player = playerRef.current = videojs(videoRef.current, videoJsOptions)

    player.src(src)
    player.ready(onPlayerReady)

  }, [src, onPlayerReady, playerRef])

  /**
   * if the player is ready and the src is changed, we need to update the player
   */
  const updatePlayer = useCallback(() => {
    const player = playerRef.current
    if (player === null) return
    player.src(src)
  }, [src])

  useEffect(() => {
    return () => {
      if (playerRef.current === null) return
      playerRef.current.dispose()
      playerRef.current = null
    }
  }, [])

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    initPlayer()
    updatePlayer()
  }, [initPlayer, updatePlayer])

  return <div data-vjs-player>
    <video
      {...rest}
      id="video-player"
      ref={videoRef}
      className="video-js !w-full !h-full !p-0"
      preload={preload?.toString()}
      playsInline
    />
  </div>
}

export default VideoJS