import { useContext } from 'react'
import EventContext from '../events/EventContext'

const ProductPreviewWrapper = (props) => {
  const { children } = props
  const { eventOptions } = useContext(EventContext)
  const enablePreview = eventOptions.includes('enable-preview')
  if (!enablePreview) {
    const { product } = props
    return <a {...props} href={product?.productUrl} target="_blank" rel="noreferrer">{children}</a>
  }
  return <div {...props}>{children}</div>
}

const ProductPreview = ({ product, onClick  }) => {
 
  return <ProductPreviewWrapper
    product={product}
    className="relative block cursor-pointer select-none outline-none"
    onClick={onClick}>
    <img alt={product.title} src={product.imageUrl} className="w-full h-full object-cover" />
  </ProductPreviewWrapper>
}

export default ProductPreview