import { useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { setItemSafe } from '../../util/localStorageUtil'
import useStateWithLocalStorage from '../../util/useStateWithLocalStorage'
import EventContext from '../events/EventContext'
import ManagementForm from './ManagementForm'

const ManagementEventId = () => {

  const [eventId, setEventId] = useStateWithLocalStorage('lastEventId', '')
  const { setEvent } = useContext(EventContext)
  const navigate = useNavigate()

  const onSubmit = useCallback(() => {
    if (!eventId) return
    setEvent(eventId, [])
    setItemSafe('lastEventId', eventId)
    navigate(`/mod/${eventId}`)
  }, [eventId, setEvent, navigate])

  return <ManagementForm title="Please enter your Event ID" onSubmit={onSubmit}>
    <div>
      <label htmlFor="event-id" className="block text-sm font-medium text-gray-700">Event ID</label>
      <div className="mt-1">
        <input value={eventId} onChange={(e) => setEventId(e.target.value.trim())} id="event-id" name="event" type="text" autoComplete="off" required autoFocus className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" />
      </div>
    </div>

    <div>
      <button type="submit" className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">OK</button>
    </div>

    <div className="flex items-center justify-end">
      <div className="text-sm">
        <a href="mailto:support@covr.app" className="font-medium text-indigo-600 hover:text-indigo-500">Forgot your Event ID?</a>
      </div>
    </div>
  </ManagementForm>

}

export default ManagementEventId
