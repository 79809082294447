import { useCallback, useContext, useEffect, useState } from 'react'
import { getItemSafe, setItemSafe } from '../../util/localStorageUtil'
import ManagementContext from './ManagementContext'
import ManagementForm from './ManagementForm'

const ManagementLogin = () => {

  const [loading, setLoading] = useState(false)
  const [authToken, setAuthToken] = useState('')
  const { setAuthToken: setContextAuthToken } = useContext(ManagementContext)

  useEffect(() => {
    setContextAuthToken(getItemSafe('authToken'))
  }, [setContextAuthToken])

  const onSubmit = useCallback(() => {
    setItemSafe('authToken', authToken)

    setLoading(true)
    setAuthToken(authToken)

    setContextAuthToken(authToken)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [setContextAuthToken, authToken])

  return <ManagementForm title="Sign in to your account" onSubmit={onSubmit}>
    <div>
      <label htmlFor="email" className="block text-sm font-medium text-gray-700"> Email address </label>
      <div className="mt-1">
        <input disabled={loading} id="email" name="email" type="email" autoComplete="email"  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" />
      </div>
    </div>

    <div>
      <label htmlFor="password" className="block text-sm font-medium text-gray-700"> Password </label>
      <div className="mt-1">
        <input disabled={loading} value={authToken} onChange={(e) => setAuthToken(e.target.value)} id="password" name="password" type="password" autoComplete="current-password" required className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" />
      </div>
    </div>

    <div>
      <button disabled={loading} type="submit" className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Sign in</button>
    </div>

    <div className="flex items-center justify-end">
      <div className="text-sm">
        <a href="mailto:support@covr.app" className="font-medium text-indigo-600 hover:text-indigo-500"> Forgot your password? </a>
      </div>
    </div>
  </ManagementForm>

}

export default ManagementLogin