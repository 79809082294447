import { useContext } from 'react'
import ProductContext from './ProductContext'
import ProductPreview from './ProductPreview'

const ProductBundleThumbs = ({ bundle, isActive }) => {

  const { onClickProduct } = useContext(ProductContext)

  return <div className={`relative w-full flex flex-wrap mt-2 mb-8 ${isActive ? 'lg:opacity-100' : 'lg:opacity-75 hover:opacity-100'}`}>
    {bundle.products.map(product => {
      return <div key={product.uid}
        className={`overflow-hidden shrink-0 will-change-transform transform duration-500 ease-in-out pr-1 pb-1 ${isActive ? 'w-1/3 lg:w-1/2 xl:w-1/3' : 'w-1/4 lg:w-1/3 xl:w-1/4'}`}>
        <ProductPreview product={product} onClick={() => onClickProduct(product)} />
      </div>
    })}
  </div>

}

export default ProductBundleThumbs
