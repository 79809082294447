import { useCallback, useMemo, useState } from 'react'
import PreferencesContext from './PreferencesContext'

const PreferencesContextProvider = ({ children }) => {

  const [userShowChat, setUserShowChat] = useState(true)
  const [userVideoMuted, setUserVideoMuted] = useState(false)
  const toggleChat = useCallback(() => {
    setUserShowChat(userShowChat => !userShowChat)
  }, [])
  const toggleMute = useCallback(() => {
    setUserVideoMuted(userMuteVideo => !userMuteVideo)
  }, [])

  const preferencesContext = useMemo(() => ({
    userVideoMuted,
    userShowChat,
    setUserShowChat,
    setUserVideoMuted,
    toggleChat,
    toggleMute,
  }), [
    userVideoMuted,
    userShowChat,
    setUserShowChat,
    setUserVideoMuted,
    toggleChat,
    toggleMute,
  ])

  return <PreferencesContext.Provider value={preferencesContext}>
    {children}
  </PreferencesContext.Provider>

}

export default PreferencesContextProvider
