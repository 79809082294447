import { useContext, useMemo } from 'react'
import { ReplyIcon } from '@heroicons/react/solid'
import { formatMessageLinks, isMessageFromModerator, isMessageVisible } from '../../util/messageUtil'
import InlineProductBundle from '../products/InlineProductBundle'
import ManagementContext from '../management/ManagementContext'
import EventContext from '../events/EventContext'

const StyledChatMessage = ({ message = null, parentMessage = null, bundle = null, dim = false }) => {

  const { moderatorName } = useContext(EventContext)
  const { authToken } = useContext(ManagementContext)
  const isParentVisibleOnFrontend = useMemo(() => isMessageVisible(parentMessage, authToken), [authToken, parentMessage])
  const isModeratorMessage = useMemo(() => isMessageFromModerator(message), [message])
  const displayName = useMemo(() => isModeratorMessage ? moderatorName : message?.displayName, [moderatorName, isModeratorMessage, message])
  const { messageContent } = message
  const formattedMessageContent = useMemo(() => {
    if (!isModeratorMessage) return messageContent
    return formatMessageLinks(messageContent)
  }, [messageContent, isModeratorMessage])
  if (message === null) return null
  const isVisibleOnFrontend = isMessageVisible(message, null)
  const textDecoration = isVisibleOnFrontend ? ' ' : ' line-through'
  const textColor = isModeratorMessage ? ' text-white ' : ' text-white lg:text-slate-900 '
  let backgroundColor = ''
  const opacity = dim ? 'opacity-25' : ''
  if (isModeratorMessage) {
    backgroundColor = isVisibleOnFrontend ? ' bg-black bg-opacity-95 ' : ' bg-black bg-opacity-50 '
  } else {
    backgroundColor = isVisibleOnFrontend ? ' bg-black lg:bg-slate-100 bg-opacity-40 ' : ' lg:bg-slate-100 bg-opacity-25 opacity-25 '
  }
  return <div className={'max-w-full ' + textColor + backgroundColor + opacity}>
    <div className={'text-ellipsis overflow-hidden flex-1 px-2 py-1 space-x-1 text-xs sm:text-sm leading-tight ' + textDecoration}>
      {isParentVisibleOnFrontend && <>
        <div className="inline-flex gap-1 opacity-75">
          <ReplyIcon className="h-4 w-4 self-center mx-auto text-white" />
          <StyledChatMessage message={parentMessage} />
        </div>
        <br />
      </>}
      <span className="font-medium pr-1.5">{displayName}</span>
      <span>{formattedMessageContent}</span>
    </div>
    {bundle !== null && <InlineProductBundle bundle={bundle} />}
  </div>
  

}

export default StyledChatMessage
