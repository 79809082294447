import { useContext } from 'react'
import EventContext from './events/EventContext'
import PageNotFound from './PageNotFound'
import NotStarted from './NotStarted'
import ThanksForWatching from './ThanksForWatching'
import VideoPlayer from './stream/videoPlayer/VideoPlayer'

const EventPage = () => {

  const { isLive, isUnkown, isNotStarted, isStarted, isProcessing, isReplay, isReLive } = useContext(EventContext)
  
  if (isUnkown) return <PageNotFound />
  if (isNotStarted) return <NotStarted />
  if (isProcessing) return <ThanksForWatching />
  if (isReplay || isReLive) return <VideoPlayer />
  if (isLive && isStarted) return <VideoPlayer />
  if (isLive && !isStarted) return <NotStarted />
  if (!isLive && isStarted) return <NotStarted />
  return null
}

export default EventPage