import { useCallback, useEffect, useState } from 'react'
import { getItemSafe, removeItemSafe, setItemSafe } from './localStorageUtil'

const useStateWithLocalStorage = (localStorageKey, defaultValue = null) => {
  const [value, setValue] = useState(getItemSafe(localStorageKey) || defaultValue)

  useEffect(() => {
    if (value) setItemSafe(localStorageKey, value)
    if (!value) removeItemSafe(localStorageKey)
  }, [localStorageKey, value])

  const storageUpdated = useCallback((storageEvent) => {
    const { key, newValue } = storageEvent
    if (key === localStorageKey) setValue(newValue)
  }, [localStorageKey])

  useEffect(() => {
    window.addEventListener('storage', storageUpdated)
    return () => {
      window.removeEventListener('storage', storageUpdated)
    }
  }, [storageUpdated])

  return [value, setValue]
}

export default useStateWithLocalStorage
