import { useContext, useMemo } from 'react'
import EventContext from './events/EventContext'
import IntroPlayer from './stream/videoPlayer/IntroPlayer'

const PosterWrapper = ({ children, allowVideo = false }) => {

  const { previewVideoUrl, isNotStarted, isReplay, isRelive, posterUrl, recordingThumbnail } = useContext(EventContext)

  const isImage = useMemo(() => !!posterUrl, [posterUrl])
  const isVideo = useMemo(() => isNotStarted && allowVideo && !!previewVideoUrl, [allowVideo, isNotStarted, previewVideoUrl])

  const poster = useMemo(() => {
    if ((isReplay || isRelive) && recordingThumbnail) return recordingThumbnail
    if (isVideo) return previewVideoUrl || ''
    if (isImage) return posterUrl || ''
    return ''
  }, [isImage, isVideo, previewVideoUrl, posterUrl, isReplay, isRelive, recordingThumbnail])
  
  const style = useMemo(() => {
    if (isVideo) return {}
    return { backgroundImage: `url(${poster})` }
  }, [isVideo, poster])

  return <div className="min-h-full flex flex-col bg-cover bg-center relative" style={style}>
    {isVideo && <IntroPlayer className="absolute inset-0 w-full h-full object-cover" src={previewVideoUrl} />}
    <main className="flex-grow flex flex-col justify-center mx-auto min-w-[240px] w-2/3 sm:w-1/2">
      <div className="aspect-w-1 aspect-h-1">
        <div className="flex-grow flex flex-col text-center bg-black bg-opacity-40 rounded-full text-white justify-center items-center">
          {children}
        </div>
      </div>
    </main>
  </div>
}

export default PosterWrapper
