import { useCallback } from 'react'
import useQueryParams from '../util/useQueryParams'
import { XIcon } from '@heroicons/react/solid'

const IFrameCloseButton = () => {

  const { 'api-key': apiKey } = useQueryParams()

  const onClickClose = useCallback(() => {
    window.parent.postMessage('close', '*')
  }, [])

  const showCloseButton = apiKey && window.self !== window.top

  if (!showCloseButton) return null

  return <div className="absolute top-0 right-0 m-1">
    <button className="bg-transparent border-transparent text-white lg:text-black lg:bg-white lg:bg-opacity-80 py-3 px-3 rounded-full" onClick={onClickClose}>
      <XIcon className="fill-current h-6 w-6 drop-shadow lg:drop-shadow-none" />
    </button>
  </div>

}

export default IFrameCloseButton
