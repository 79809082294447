import EventModeratorNameInput from './EventModeratorNameInput'
import EventStartStreamButton from './EventStartStreamButton'
import EventStatusSelect from './EventStatusSelect'

const EventControl = () => {

  return <div className='flex-shrink-0 p-10 bg-red-500 h-120'>
    <h1 className='text-white text-2xl font-semibold'>Event Status</h1>
    <EventStatusSelect />
    <h1 className='text-white text-2xl font-semibold mt-6'>Recorded Stream</h1>
    <EventStartStreamButton />
    <h1 className='text-white text-2xl font-semibold mt-6'>Moderator Name</h1>
    <EventModeratorNameInput />
  </div>

}

export default EventControl
