const useQueryParams = (defaultValues = {}) => {
  const params = new URLSearchParams(
    window ? window.location.search : {}
  );

  return new Proxy(params, {
    get(target, prop) {
      const value = target.get(prop)
      if (!value) return defaultValues[prop];
      return value
    },
  });
}

export default useQueryParams