import { RefreshIcon, TrashIcon, UserRemoveIcon, ReplyIcon } from '@heroicons/react/solid'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { sendBanUserAction, sendMessageDeleteAction, sendMessageRestoreAction } from '../../util/connection/viewerActionSender'
import { isMessageFromModerator, isMessageVisible } from '../../util/messageUtil'
import { USER_MUTED, DELETED } from '../../util/messageVisibilityStatus'
import EventContext from '../events/EventContext'
import ManagementContext from '../management/ManagementContext'
import ProductContext from '../products/ProductContext'
import ChatContext from './ChatContext'
import StyledChatMessage from './StyledChatMessage'

const ModerationButtons = ({ message }) => {

  const { setReplyingTo } = useContext(ChatContext)
  const { webSocket, eventId } = useContext(EventContext)
  const { authToken } = useContext(ManagementContext)
  const { visibilityStatus } = message
  const isModeratorMessage = isMessageFromModerator(message)

  const answerMessage = useCallback(() => {
    setReplyingTo(message)
  }, [message, setReplyingTo])

  const deleteMessage = useCallback(() => {
    sendMessageDeleteAction(webSocket, authToken, message, eventId)
  }, [webSocket, authToken, message, eventId])

  const restoreMessage = useCallback(() => {
    sendMessageRestoreAction(webSocket, authToken, message, eventId)
  }, [webSocket, authToken, message, eventId])

  const banUser = useCallback(() => {
    const { userId } = message
    sendBanUserAction(webSocket, authToken, userId, eventId)
  }, [webSocket, authToken, message, eventId])

  const isDeleted = visibilityStatus === DELETED
  const canBanUser = isDeleted && !isModeratorMessage && visibilityStatus !== USER_MUTED

  return <div className="flex flex-row gap-2 text-slate-400">
    {!isDeleted && <TrashIcon className="h-4 w-4 mx-auto cursor-pointer hover:text-black" onClick={deleteMessage} />}
    {!isModeratorMessage && <ReplyIcon className="h-4 w-4 mx-auto cursor-pointer hover:text-black" onClick={answerMessage} />}
    {isDeleted && <RefreshIcon className="h-4 w-4 mx-auto cursor-pointer hover:text-black" onClick={restoreMessage} />}
    {canBanUser && <UserRemoveIcon className="h-4 w-4 mx-auto cursor-pointer hover:text-black" onClick={banUser} />}
  </div>

}

const ChatMessage = ({ message, parentMessage = null, className = '' }) => {

  const { authToken } = useContext(ManagementContext)
  const { getBundle } = useContext(ProductContext)
  const { replyingTo, showInlineBundles } = useContext(ChatContext)
  const [bundle, setBundle] = useState(null)
  const isModeratorMessage = useMemo(() => isMessageFromModerator(message), [message]) 

  useEffect(() => {
    setBundle(getBundle(message.itemId))
  }, [message.itemId, getBundle])

  if (!isMessageVisible(message, authToken)) return null

  const dimMessage = replyingTo !== null && replyingTo !== message
  const showInlineBundle = showInlineBundles && !isModeratorMessage && bundle

  return <div className={'inline-flex flex-shrink px-2 gap-1 items-center ' + className}>
    {authToken && <ModerationButtons message={message} />}
    <StyledChatMessage
      message={message}
      parentMessage={parentMessage}
      bundle={showInlineBundle ? bundle : null}
      dim={dimMessage}
    />
  </div>

}

export default ChatMessage
