import { useContext } from 'react'
import EventContext from '../events/EventContext'
import Chat from './Chat'
import SignIn from './SignIn'

const UserChat = () => {
  const { chatIsInStream } = useContext(EventContext)
  return <Chat immediate={!chatIsInStream}>
    <SignIn />
  </Chat>
}

export default UserChat
