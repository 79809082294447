import { useContext, useEffect, useMemo } from 'react'
import * as actions from '../../util/connection/actions'
import ConnectionHandler from '../../util/connection/ConnectionHandler'
import { initEvent } from '../../util/connection/viewerActionSender'
import useStateWithLocalStorage from '../../util/useStateWithLocalStorage'
import EventContext from '../events/EventContext'
import ManagementContext from './ManagementContext'

const ManagementContextProvider = ({ children }) => {

  const { eventId, webSocket, lastMessage } = useContext(EventContext)
  const [authToken, setAuthToken] = useStateWithLocalStorage('authToken', null)
  const isMod = useMemo(() => authToken !== null, [authToken])

  useEffect(() => {
    ConnectionHandler(lastMessage, actions.ACTION_UPDATED_EVENT, () => {
      initEvent(webSocket, eventId)
    })
  }, [webSocket, lastMessage, eventId])

  const context = useMemo(() => ({
    authToken,
    setAuthToken,
    isMod,
  }), [authToken, setAuthToken, isMod])

  return <ManagementContext.Provider value={context}>
    {children}
  </ManagementContext.Provider>

}

export default ManagementContextProvider
