/**
 * The websocket api gateway route for the "AddStreamId" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_INIT = 'Init'

/**
 * The websocket api gateway route for the "Login" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_LOGIN = 'Login'

/**
 * The websocket api gateway route for the "ModLogin" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_MOD_LOGIN = 'ModLogin'

/**
 * The websocket api gateway route for the "UpdateUsername" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_PUT_USERNAME = 'PutUsername'

/**
 * The websocket api gateway route for the "RetrieveMessages" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_GET_MESSAGES = 'GetMessages'

/**
 * The websocket api gateway route for the "RetrieveMessages" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_GET_DELETED_MESSAGES = 'GetDeletedMessages'

/**
 * The websocket api gateway route for the "WriteMessage" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_PUT_MESSAGE = 'PutMessage'

export const ACTION_PUT_MOD_MESSAGE = 'PutModMessage'

/**
 * The websocket api gateway route for the "DeleteMessage" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_DELETE_MESSAGE = 'DeleteMessage'

/**
 * The websocket api gateway route for the "RestoreMessage" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_RESTORE_MESSAGE = 'RestoreMessage'

/**
 * The websocket api gatewayroute for the "Reaction" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_PUT_REACTION = 'PutReaction'

/**
 * The websocket api gateway route for the "ListUsers" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_LIST_USERS = 'ListUsers'

/**
 * The websocket api gateway route for the "ListBannedUsers" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_LIST_BANNED_USERS = 'ListBannedUsers'

/**
 * The websocket api gateway route for the "ListNonBannedUsers" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_LIST_NON_BANNED_USERS = 'ListNonBannedUsers'

/**
 * The websocket api gateway route for the "BanUser" command.
 * Also the response action for direct responses coming from requests sent via the same route.
 */
export const ACTION_BAN_USER = 'BanUser'

/**
 * The websocket api gateway route for the "UnbanUser" command.
 * Also the response action for direct responses coming from request sent via the same route.
 */
export const ACTION_UNBAN_USER = 'UnbanUser'

/**
 * The response action for a new incoming message.
 */
export const ACTION_NEW_MESSAGE_RECEIVED = 'NewMessage'

/**
 * The response action for an incoming updated message.
 */
export const ACTION_UPDATED_MESSAGE_RECEIVED = 'UpdatedMessage'

/**
 * The action that indicates that an event has been updated
 */
export const ACTION_UPDATED_EVENT = 'UpdatedEvent'

/**
 * The action that activates current product sets
 */
export const ACTION_PUT_ITEMS = 'PutItems'

/**
 * The action that updates a given event
 */
export const ACTION_PUT_EVENT = 'PutEvent'

/**
 * The action that updates the given s3 url for the stream
 */
export const ACTION_PUT_S3_STREAM = 'PutS3Stream'

/**
 * The action to retrieve all recordings for an event
 */
export const ACTION_GET_RECORDINGS = 'GetRecordings'