import { VolumeOffIcon, VolumeUpIcon } from '@heroicons/react/solid'
import { useContext } from 'react'
import PreferencesContext from '../../preferences/PreferencesContext'

const ToggleMuteButton = () => {

  const { userVideoMuted, toggleMute } = useContext(PreferencesContext)

  if (userVideoMuted) return <VolumeOffIcon className="h-6 w-6 drop-shadow" onClick={toggleMute} />
  return <VolumeUpIcon className="h-6 w-6 drop-shadow" onClick={toggleMute} />

}

export default ToggleMuteButton
