import { useContext } from 'react'
import ProductContext from './ProductContext'
import ProductPreviewAnimated from './ProductPreviewAnimated'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import FeatureContext from '../features/FeatureContext'

const ProductPreviewContainer = () => {

  const { showProductBundles, showActiveProductBundle } = useContext(FeatureContext)
  const productContext = useContext(ProductContext)
  const { activeBundle, productBundles } = productContext
  const { products } = activeBundle || {}

  const onClick = (product) => () => {
    productContext.onClickProduct(product)
  }

  if (!showProductBundles) return null

  return <div className="absolute top-0 inset-x-0 h-1/2 w-1/5 lg:w-1/6 lg:h-2/3 overflow-hidden flex flex-col space-y-2 pt-2 pl-2 pr-4">
    {showActiveProductBundle && activeBundle && <div className='flex-shrink space-y-1.5 md:space-y-2 overflow-y-auto scrollbar-hide'>
      {products.map((product, index) => {
        return <ProductPreviewAnimated key={product.uid} index={index} product={product} onClick={onClick(product)} />
      })
      }
    </div>}
    {/* display only if products length > 0 */}
    {productBundles && productBundles.length > 0 && <div className="lg:hidden flex-shrink-0 w-full relative">
      <div className="block cursor-pointer select-none bg-white shadow-lg
      will-change-transform transition-transform ease-in-out duration-100 
      scale-100 hover:scale-95
      bg-opacity-80 hover:bg-opacity-100" onClick={() => productContext.onOpenProductBundles()}>
        <DotsHorizontalIcon className='h-6 w-6 mx-auto'/>
      </div>  
    </div>  
    }

  </div>
}

export default ProductPreviewContainer
