import ProductBundleThumbs from './ProductBundleThumbs'

const InlineProductBundle = ({ bundle, onClickProduct }) => {

  return <div className='max-w-full w-40 -mt-2 lg:-mt-4 -mb-8'>
    <ProductBundleThumbs bundle={bundle} onClickProduct={onClickProduct} />
  </div>
}

export default InlineProductBundle
