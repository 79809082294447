import * as actions from './actions'
import { sendAction, sendRequest } from './actionSender'

const defaultMessageOptions = {
  ScanIndexForward: false
}

// TODO (GM): Add option with LastEvaluatedKey later for "Load more" button.
/**
 * Sends the "RetrieveMessages" command.
 * This command is used to retrieve messages for only this and only this client.
 * Use this on load to get the latest messages.
 * @param socket The connection to send the command to.
 */
export const getMessages = (socket, options = { }) => {
  sendAction(socket, {
    action: actions.ACTION_GET_MESSAGES,
    data: {
      ...defaultMessageOptions,
      ...options,
      LastEvaluatedKey: options?.key || null,
      requestId: options?.requestId || null,
    }
  })
}

export const getDeletedMessages = (socket, eventId) => {
  sendAction(socket, { action: actions.ACTION_GET_DELETED_MESSAGES, data: { eventId } })
}

export const getMessagesAsync = async (socket, options = { }) => {
  const response = await sendRequest(socket, {
    action: actions.ACTION_GET_MESSAGES,
    data: {
      ...defaultMessageOptions,
      ...options,
      LastEvaluatedKey: options?.key || null,
      requestId: options?.requestId || null,
    }
  })
  return response?.Items || []
}

// TODO use real event id for message actions

export const sendItems = (socket, authToken, bundleId) => {
  sendAction(socket, {
    action: actions.ACTION_PUT_ITEMS,
    token: authToken,
    data: { items: bundleId ? [ bundleId ] : [] },
  })
}

export const getBannedUsers = (socket, token, eventId) => {
  sendAction(socket, { action: actions.ACTION_LIST_BANNED_USERS, token, data: { eventId } })
}

export const putEventStatus = (socket, token, eventId, statusId) => {
  sendAction(socket, { action: actions.ACTION_PUT_EVENT, token, data: { eventId, statusId } })
}

export const putEventMetadata = (socket, token, eventId, metadata) => {
  sendAction(socket, { action: actions.ACTION_PUT_EVENT, token, data: { eventId, metadata } })
}

export const putEventModeratorName = (socket, token, eventId, moderatorName) => {
  sendAction(socket, { action: actions.ACTION_PUT_EVENT, token, data: { eventId, moderatorName } })
}

export const putS3Stream = (socket, token, streamfileKey) => {
  sendAction(socket, { action: actions.ACTION_PUT_S3_STREAM, token, data: { streamfileKey }})
}

export const getRecordings = (socket, eventId) => {
  sendAction(socket, { action: actions.ACTION_GET_RECORDINGS, data: { eventId } })
}

/**
 * Sends the "WriteMessage" command.
 * This command will write the passed message content to the backend and notify all clients of the new mesage.
 * There's no need to pass any user info or the like - it will be set in the backend dependant on the requesting client.
 * @param connection The connection to send the command to.
 * @param messageContent The content of the message to write to the database.
 */
export const sendMessageAction = (socket, token, messageContent, parentMessage, itemId, eventId) => {
  const action = token ? actions.ACTION_PUT_MOD_MESSAGE : actions.ACTION_PUT_MESSAGE
  const data = { messageContent }
  if (parentMessage !== null) data.parentId = parentMessage.messageId
  if (itemId) data.itemId = itemId // add itemId if available
  if (token) data.eventId = eventId // add eventId for mod messages
  const actionData = { action, data }
  if (token) actionData.token = token
  sendAction(socket, actionData)
}

/**
 * answer a message with the given id
 * @param {WebSocket} socket the web socket
 * @param {string|null} token the moderation token or null
 * @param {string} messageContent the message content
 * @param {string} messageId the id of the message to answer
 * @param {string} eventId the event id
 */
export const sendAnswerMessageAction = (socket, token, messageContent, messageId, eventId) => {
  if (!token) return false
  const data = { messageContent, parentId: messageId, eventId }
  const actionData = { action: actions.ACTION_PUT_MOD_MESSAGE, data, token }
  sendAction(socket, actionData)
  return true
}

export const sendMessageDeleteAction = (socket, token, message, eventId) => {
  if (!message) return
  const { messageId } = message
  const actionData = { action: actions.ACTION_DELETE_MESSAGE, token, data: { messageId, eventId } }
  sendAction(socket, actionData)
}

export const sendMessageRestoreAction = (socket, token, message, eventId) => {
  if (!message) return
  const { messageId } = message
  const actionData = { action: actions.ACTION_RESTORE_MESSAGE, token, data: { messageId, eventId } }
  sendAction(socket, actionData)
}

export const sendBanUserAction = (socket, token, userId, eventId) => {
  const actionData = { action: actions.ACTION_BAN_USER, token, data: { userId, eventId } }
  sendAction(socket, actionData)
}

export const sendUnbanUserAction = (socket, token, userId, eventId) => {
  const actionData = { action: actions.ACTION_UNBAN_USER, token, data: { userId, eventId } }
  sendAction(socket, actionData)
}

/**
 * Adds the StreamId to the connection object of this client in the database.
 * The StreamId is needed to be able to always retrieve the latest messages.
 * @param connection The connection send the command to.
 * @param eventId The event id to add the connection.
 */
export const initEvent = (socket, eventId) => {
  sendAction(socket, { action: actions.ACTION_INIT, data: { eventId } })
}

/**
 * Send one reaction with the given content
 * @param connection The connection to send the command to.
 * @param reaction The reaction (e.g. an emoji) to write to the database.
 */
export const sendReactionCommand = (connection, reaction) => {
  sendAction(connection, { action: actions.ACTION_PUT_REACTION, data: { reaction } })
}

/**
 * Sends the "Login" command.
 * If the username is already taken an error response will be returned.
 * Otherwise a random id will be assigned to this user.
 * @param connection The connection to send the command to.
 * @param displayName The username to log in as. If it's already taken an error response will be returned.
 */
export const sendLoginCommand = (socket, displayName) => {
  sendAction(socket, { action: actions.ACTION_LOGIN, data: { displayName } })
}

// TODO (GM): Implement OAuth and shit!
// TODO (GM): Is it needed to open a new, seperate connection here? Can the client only be authorized on the $connect function?
/**
 * Sends the command to try to login as a moderator.
 * @param connection The connection to send the command to.
 */
export const sendModLoginCommand = (socket) => {
  sendAction(socket, { action: actions.ACTION_MOD_LOGIN, data: {} })
}

/**
 * Sends the "UpdateUsername" command.
 * If both the passed userid and username match the database entry and the connection saved in the database is no longer active the userid and username will be assigned to the requesting client.
 * @param connection The connection to send the command to.
 * @param userInfo The user info containing the id and username to send with the command.
 */
export const sendUpdateUsernameCommand = (socket, userInfo) => {
  sendAction(socket, { action: actions.ACTION_PUT_USERNAME, data: userInfo })
}
