import { useContext } from 'react'
import ProductContext from '../products/ProductContext'
import ManagableProductBundle from './ManagableProductBundle'

const BundleManager = () => {

  const productContext = useContext(ProductContext)

  return <div className="flex flex-col gap-1">
    {productContext.productBundles?.map(bundle => {
      return <ManagableProductBundle key={bundle.uid} bundle={bundle} />
    })}
  </div>

}

export default BundleManager
