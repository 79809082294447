import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Management from './management/Management'
import LivestreamWidget from './stream/LivestreamWidget'
import PageNotFound from './PageNotFound'
import ModOutlet from './management/ModOutlet'
import ContextOutlet from './ContextOutlet'
import IFrameCloseButton from './IFrameCloseButton'

/**
 * @returns Returns the App JSX containing the whole player with all functionalitiy (chat, sign in, etc.).
 */
const App = () => {

  return (<Router>
    <div className="relative flex flex-grow overflow-hidden bg-black">
      <Routes>
        <Route path="/" element={<ContextOutlet />}>
          <Route path="/mod" element={<ModOutlet />} >
            <Route path="/mod/" element={<Management />} />
            <Route path="/mod/:eventId" element={<Management />} />
          </Route>
          <Route path="/" element={<LivestreamWidget />} />
          <Route path="/not-found" element={<PageNotFound />} />
          <Route path="/:eventId" element={<LivestreamWidget />} />
        </Route>
      </Routes>
      <IFrameCloseButton />
    </div>
  </Router>)
}

export default App;
