import { useContext, useMemo } from 'react'
import EventContext from '../events/EventContext'
import TimedMetadataContext from '../events/TimedMetadataContext'
import FeatureContext from './FeatureContext'

const FeatureContextProvider = ({ children }) => {

  const { isInit: isTimedMetadataInit } = useContext(TimedMetadataContext)
  const { isInit: isEventInit, isReplay, isLive, isReLive, isNotStarted, isStarted, isPlaying, isProcessing, isUnknown } = useContext(EventContext)

  const isInit = useMemo(() => isTimedMetadataInit && isEventInit, [isTimedMetadataInit, isEventInit])
  const showChat = useMemo(() => {
    if (isNotStarted) return false
    return (isPlaying || isProcessing) && isEventInit
  }, [isNotStarted, isEventInit, isPlaying, isProcessing])
  const showChatInput = useMemo(() => {
    return !isReplay && showChat
  }, [isReplay, showChat])
  const showBigPlayButton = useMemo(() => !isPlaying && (isLive || isReLive || (isReplay && !isTimedMetadataInit)), [isPlaying, isLive, isReLive, isReplay, isTimedMetadataInit])
  const showReactions = useMemo(() => {
    if (isNotStarted) return false
    return isPlaying && isInit && (isLive || isReLive || isReplay)
  }, [isNotStarted, isInit, isLive, isReLive, isPlaying, isReplay])
  const showReactionBar = useMemo(() => isLive && isInit, [isLive, isInit])
  const showActiveProductBundle = useMemo(() => isInit && !isProcessing, [isInit, isProcessing])
  const showProductBundles = useMemo(() => {
    if (isNotStarted) return false
    return (isPlaying || isProcessing) && isEventInit
  }, [isNotStarted, isEventInit, isPlaying, isProcessing])
  const showBundleActivateButtons = useMemo(() => isLive && isEventInit, [isLive, isEventInit])

  const featureContext = useMemo(() => ({
    showChat,
    showChatInput,
    showBigPlayButton,
    showReactions,
    showReactionBar,
    showProductBundles,
    showActiveProductBundle,
    showBundleActivateButtons,
  }), [
    showChat,
    showChatInput,
    showBigPlayButton,
    showReactions,
    showReactionBar,
    showProductBundles,
    showActiveProductBundle,
    showBundleActivateButtons,
  ])

  return <FeatureContext.Provider value={featureContext}>
    {children}
  </FeatureContext.Provider>

}

export default FeatureContextProvider
