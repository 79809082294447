import { Outlet } from 'react-router-dom'

import EventContextProvider from './events/EventContextProvider'
import FeatureContextProvider from './features/FeatureContextProvider'
import PreferencesContextProvider from './preferences/PreferencesContextProvider'
import ProductContextProvider from './products/ProductContextProvider'
import ReactionContextProvider from './reactions/ReactionContextProvider'
import TimedMetadataContextProvider from './events/TimedMetadataContextProvider'
import UserContextProvider from './userControl/UserContextProvider'
import StreamContextProvider from './stream/StreamContextProvider'
import ChatMessageProvider from './chat/ChatMessageProvider'

const ContextOutlet = () => {

  return <>
    <TimedMetadataContextProvider>
      <EventContextProvider>
        <UserContextProvider>
          <ProductContextProvider>
            <ReactionContextProvider>
              <FeatureContextProvider>
                <PreferencesContextProvider>
                  <StreamContextProvider>
                    <ChatMessageProvider>
                      <Outlet />
                    </ChatMessageProvider>
                  </StreamContextProvider>
                </PreferencesContextProvider>
              </FeatureContextProvider>
            </ReactionContextProvider>
          </ProductContextProvider>
        </UserContextProvider>
      </EventContextProvider>
    </TimedMetadataContextProvider>
  </>

}

export default ContextOutlet
