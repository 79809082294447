import { Fragment, useCallback, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import ProductContext from './ProductContext'
import FeatureContext from '../features/FeatureContext'

const ProductModal = () => {

  const { showProductBundles } = useContext(FeatureContext)
  const { selectProduct: product, showProductModal: show, onCloseProductModal } = useContext(ProductContext)

  const closeModal = useCallback(() => {
    onCloseProductModal()
  }, [onCloseProductModal])

  if (!showProductBundles) return null
  if (product === null) return null

  return <Transition appear show={show} as={Fragment}>
    <Dialog as="div" className="relative z-12" onClose={closeModal}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div
                className="w-full h-80 aspect-video block bg-cover bg-no-repeat bg-center"
                style={{ backgroundImage: `url(${product.imageUrl})` }}>
              </div>
              <div className='w-full flex flex-col p-4 gap-4'>
                <div className="flex flex-col">
                  <span className="text-xl font-bold">{product.title}</span>
                  <span className="text-sm">{product.description}</span>
                </div>
                <a
                  className="w-full inline-flex justify-center border border-transparent px-4 py-2 bg-black text-base font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-transparent"
                  target="blank"
                  href={product.productUrl}>
                  {!!product.formerPrice && <del className="text-sm font-normal mr-2">{product.formerPrice}</del>}
                  {product.price} Buy now
                </a>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>

}

export default ProductModal