import UserListItem from './UserListItem'

const UserList = ({ users }) => {

  return <div>
    {users.map(user => {
      return <UserListItem key={user.id} user={user} />
    })}
  </div>

}

export default UserList
