import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import ChatMessages from './ChatMessages'

import { sendMessageAction } from '../../util/connection/viewerActionSender'

import UserContext from '../userControl/UserContext'
import EventContext from '../events/EventContext'
import ReactionSelect from '../reactions/ReactionSelect'
import ManagementContext from '../management/ManagementContext'
import ProductContext from '../products/ProductContext'
import ChatContext from './ChatContext'
import FeatureContext from '../features/FeatureContext'
import PreferencesContext from '../preferences/PreferencesContext'

/**
 * @returns Returns the Chat React element containing the sign in, the video player as well as the actual chat.
 */
export default function Chat({ autoScrollOption = false, immediate = false, children }) {

  const chatInput = useRef(null)
  const { setUserShowChat } = useContext(PreferencesContext)
  const { authToken, isMod } = useContext(ManagementContext)
  const [replyingTo, setReplyingTo] = useState(null) // replying to message
  const showInlineBundles = useMemo(() => isMod, [isMod])
  const { showChatInput } = useContext(FeatureContext)
  const { webSocket, eventId, moderatorName } = useContext(EventContext)
  const { activeBundleId } = useContext(ProductContext)
  const [user] = useContext(UserContext)
  const [autoScrollEnabled, setAutoScroll] = useState(true)
  const [inputFocused, setInputFocused] = useState(false)
  const [message, setMessage] = useState('')
  const sendMessage = useCallback((message, parentMessage, itemId) => {
    sendMessageAction(webSocket, authToken, message, parentMessage, itemId, eventId)
  }, [authToken, webSocket, eventId])
  const autoScroll = useMemo(() => autoScrollEnabled && replyingTo === null, [autoScrollEnabled, replyingTo])

  const cancelReplyingTo = useCallback(() => {
    setReplyingTo(null)
  }, [setReplyingTo])

  /**
   * Handles chat input change by setting the message to the value of the change event.
   * @param e The change event. Used to set the message.
   */
  const handleChatInputChange = (e) => {
    setMessage(e.target.value)
  }

  /**
   * Handles keydown events for the chat input field. Sends the current message if Enter is pressed and the current message is not empty. Does nothing otherwise.
   * @param e The keydown event.
   */
  const handleChatInputKeyDown = useCallback((e) => {
    if (e.key !== 'Enter') return
    if (!message) return
    sendMessage(message, replyingTo, activeBundleId)
    setMessage('')
    setReplyingTo(null)
    setUserShowChat(true)
    // blur the input element to hide the keyboard
    // e.target.blur()
  }, [message, replyingTo, sendMessage, setUserShowChat, activeBundleId])

  const onToggleAutoscroll = () => setAutoScroll(checked => !checked)

  const placeholderLabel = useMemo(() => {
    if (!user) return ''
    return `Send as ${isMod ? moderatorName : user.displayName}`
  }, [isMod, moderatorName, user])

  const chatContext = useMemo(() => ({
    showInlineBundles,
    replyingTo,
    setReplyingTo,
  }), [
    showInlineBundles,
    replyingTo,
    setReplyingTo
  ])

  const showMessageInput = user || authToken

  useEffect(() => {
    if (replyingTo === null) return
    if (chatInput.current === null) return
    chatInput.current.focus()
  }, [replyingTo])

  return <ChatContext.Provider value={chatContext}>
    {autoScrollOption && <div className="sticky m-2 text-black"><input checked={autoScrollEnabled} type="checkbox" onChange={onToggleAutoscroll} /> Autoscroll</div>}
    <ChatMessages autoScroll={autoScroll} immediate={immediate} />
    {showChatInput && <>
      <div className='bg-black bg-opacity-60'>
        {!!replyingTo && <div className="h-2 inline-flex p-2">
          <div className="text-sm text-gray-300">Replying to: <b>{replyingTo.displayName}</b></div>
          <button className="text-sm text-gray-400 px-3" onClick={cancelReplyingTo}>Cancel</button>
        </div>}
        <div id="new-message" className={'flex h-12 lg:max-w-lg flex-shrink-0 overflow-y-hidden overflow-x-scroll scrollbar-hide snap-x snap-mandatory '}>
          <div className="min-w-[50%] flex-grow flex content-center snap-normal snap-start px-2 py-2">
            {children}
            {showMessageInput && <>
              <input
                className="bg-white text-black block w-full text-sm px-4 rounded-full focus:border-transparent focus:ring-0 focus:outline-none"
                type="text"
                name="message"
                id="message"
                autoComplete="off"
                placeholder={placeholderLabel}
                value={message}
                maxLength={160}
                ref={chatInput}
                onChange={handleChatInputChange}
                onKeyDown={handleChatInputKeyDown}
                onFocus={() => setInputFocused(true)}
                onBlur={() => setInputFocused(false)}
              />
            </>}
          </div>
          {!inputFocused && <ReactionSelect />}
        </div>
      </div>
    </>}
  </ChatContext.Provider>

}
