/**
 * the event is unknown, so we can't send any actions
 */
export const EVENT_UNKNOWN = -1

/**
 * The event has not started yet
 */
export const EVENT_NOT_STARTED = 0

/**
 * The event has started
 */
export const EVENT_HAS_STARTED = 1

/**
 * The event is currently processing the replay
 */
export const EVENT_IS_PROCESSING = 2

/**
 * The replay is available
 */
export const EVENT_REPLAY_AVAILABLE = 3


/**
 * The it's a re-live event
 */
export const EVENT_IS_RELIVE = 4