import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'

import EventContext from '../../events/EventContext'
import TimedMetadataContext from '../../events/TimedMetadataContext'
import PlayIcon from '../../../icons/play'
import VideoJS from './VideoJS'
import PosterWrapper from '../../PosterWrapper'
import FeatureContext from '../../features/FeatureContext'
import PreferencesContext from '../../preferences/PreferencesContext'
import StreamContext from '../StreamContext'

/**
 * @returns Returns the video player jsx element with the video embedded (and playing).
 */
const VideoPlayer = () => {

  const { setUserHasSkipped, setVideoHasEnded } = useContext(StreamContext)
  const { setUserVideoMuted, userVideoMuted } = useContext(PreferencesContext)
  const { showBigPlayButton } = useContext(FeatureContext)
  const [isMuted, setIsMuted] = useState(false)
  const [player, setPlayer] = useState(null)
  const [videoJSPlayer, setVideoJSPlayer] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const { onIsPlaying, streamUrl, recordingUrl, isReplay, isReLive } = useContext(EventContext)
  const { setTimedMetadata } = useContext(TimedMetadataContext)
  const videoUrl = useMemo(() => ((isReplay || isReLive) && recordingUrl) ? recordingUrl : streamUrl, [streamUrl, isReplay, isReLive, recordingUrl])

  const playerRef = useRef(null);
  const ivsEvents = useMemo(() => videoJSPlayer?.getIVSEvents() || null, [videoJSPlayer])
  const PlayerState = useMemo(() => ivsEvents?.PlayerState || null, [ivsEvents])
  const PlayerEventType = useMemo(() => ivsEvents?.PlayerEventType || null, [ivsEvents])

  const handlePlayerReady = useCallback((videoJSPlayer) => {
    playerRef.current = videoJSPlayer
    const player = videoJSPlayer.getIVSPlayer()
    setVideoJSPlayer(videoJSPlayer)
    setPlayer(player)
  }, [])

  useEffect(() => {
    if (videoJSPlayer === null) return
    videoJSPlayer.muted(userVideoMuted)

    const playListener = () => {
      setIsPlaying(true)
      // console.log('PLAYER IS PLAYING')
    }
    const pauseListener = () => {
      setIsPlaying(false)
      // console.log('PLAYER IS PAUSED')
    }
    const volumeListener = () => {
      setIsMuted(videoJSPlayer.muted())
      setUserVideoMuted(videoJSPlayer.muted())
    }
    const seekListener = (seekEvent) => {
      console.log('seek event', seekEvent)
      setUserHasSkipped(true)
    }
    const endedListener = (endedEvent) => {
      console.log('video ended', endedEvent)
      setVideoHasEnded(true)
      window?.dispatchEvent(new CustomEvent('stream-ended'))
    }
    
    videoJSPlayer.on('ended', endedListener)
    videoJSPlayer.on('play', playListener)
    videoJSPlayer.on('pause', pauseListener)
    videoJSPlayer.on('seeked', seekListener)
    videoJSPlayer.on('volumechange', volumeListener)
    return () => {
      videoJSPlayer.off('ended', endedListener)
      videoJSPlayer.off('play', playListener)
      videoJSPlayer.off('pause', pauseListener)
      videoJSPlayer.off('seeked', seekListener)
      videoJSPlayer.off('volumechange', volumeListener)
    }
  }, [setUserHasSkipped, setUserVideoMuted, setVideoHasEnded, userVideoMuted, videoJSPlayer])

  /**
   * Gets the ivs player, loads the stream and attaches it to the video player html (the element with id 'video-player').
   */
  useEffect(() => {
    if (player === null) return // if the player is already set, we won't create a new one
    if (!videoUrl) return // if there is no stream url, we won't create a new player

    const listenerPlaying = () => {
      console.log('Player State - PLAYING')
      setIsPlaying(true)
      onIsPlaying(true)
    }

    const listenerEnded = () => {
      console.log('Player State - ENDED')
      setIsPlaying(false)
    }

    const listenerReady = () => {
      console.log('Player State - READY')
      if (!isPlaying) {
        player.play()
        player.setMuted(false)
      }
    }

    const listenerAudioBlocked = () => {
      console.log('Player audio was blocked by OS')
      setIsMuted(player.isMuted())
    }

    // const listenerMutedChanged = () => {
    //   console.log('Player muted changed')
    //   setIsMuted(player.isMuted())
    // }

    const listenerError = (err) => {
      console.warn('Player Event - ERROR:', err)
    }

    const listenerTextMetadataCue = (cue) => {
      setTimedMetadata(JSON.parse(cue.text))
    }

    // Attach event listeners
    player.addEventListener(PlayerState.PLAYING, listenerPlaying)
    player.addEventListener(PlayerState.ENDED, listenerEnded)
    player.addEventListener(PlayerState.READY, listenerReady)
    player.addEventListener(PlayerEventType.AUDIO_BLOCKED, listenerAudioBlocked)
    // player.addEventListener(PlayerEventType.MUTED_CHANGED, listenerMutedChanged)
    player.addEventListener(PlayerEventType.ERROR, listenerError)
    player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, listenerTextMetadataCue)

    return () => {
      if (player === null) return
      player.removeEventListener(PlayerState.PLAYING, listenerPlaying)
      player.removeEventListener(PlayerState.ENDED, listenerEnded)
      player.removeEventListener(PlayerState.READY, listenerReady)
      player.removeEventListener(PlayerEventType.AUDIO_BLOCKED, listenerAudioBlocked)
      // player.removeEventListener(PlayerEventType.MUTED_CHANGED, listenerMutedChanged)
      player.removeEventListener(PlayerEventType.ERROR, listenerError)
      player.removeEventListener(PlayerEventType.TEXT_METADATA_CUE, listenerTextMetadataCue)
    }

  }, [player, PlayerState, PlayerEventType, setTimedMetadata, isPlaying, setIsPlaying, onIsPlaying, isReplay, isReLive, videoUrl])

  useEffect(() => {
    if (videoJSPlayer === null) return
    try {
      videoJSPlayer.controls(isReplay)
    } catch (e) {

    }
  }, [isReplay, videoJSPlayer])

  useEffect(() => {
    if (videoJSPlayer === null) return
    if (!isReLive) return
    // videoJSPlayer.play()
  }, [isReLive, videoJSPlayer])

  useEffect(() => {
    if (playerRef.current === null) return
    playerRef.current.muted(isMuted)
  }, [playerRef, isMuted])

  // const toggleMuted = useCallback(() => {
  //   if (playerRef.current === null) return
  //   playerRef.current.muted(!playerRef.current.muted())
  // }, [])

  const startPlay = useCallback(() => {
    if (playerRef.current === null) return
    playerRef.current.play()
    playerRef.current.muted(false)
  }, [playerRef])

  return <div className="h-full w-full relative bg-black">
    {(isReplay || isReLive) &&
      <div className="absolute z-10 inset-x-0 flex flex-row justify-center pointer-events-none">
        <span className='mt-2 px-2 py-1 block leading-none bg-black bg-opacity-50 text-white uppercase text-xs'>RE-LIVE</span>
      </div>
    }
    {showBigPlayButton &&
      <div className="absolute z-10 inset-0">
        <PosterWrapper>
          <div className="p-10 text-white cursor-pointer flex justify-center items-stretch" onClick={startPlay}>
            <PlayIcon className="w-24 h-24 drop-shadow-lg" />
          </div>
        </PosterWrapper>
      </div>
    }
    {videoUrl && <VideoJS
      src={videoUrl}
      preload={(isReplay || isReLive) ? 'auto' : 'metadata'}
      onReady={handlePlayerReady}
    />}
  </div>
}

export default VideoPlayer
