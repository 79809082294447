import { useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import ReactionOverlay from '../reactions/ReactionOverlay'

import ProductModal from '../products/ProductModal'
import ProductPreviewContainer from '../products/ProductPreviewContainer'
import EventContext from '../events/EventContext'
import ProductBundles from '../products/ProductBundles'
import ProductBundlesBottomSheet from '../products/ProductBundlesBottomSheet'
import VideoWrapper from './videoPlayer/VideoWrapper'
import UserChat from '../chat/UserChat'
import EventPage from '../EventPage'
import FeatureContext from '../features/FeatureContext'
import UIButtons from '../ui/UIButtons'

const Livestream = () => {

  const { showProductBundles, showChat } = useContext(FeatureContext)
  const { isReplay } = useContext(EventContext)
  const isScreenLg = useMediaQuery({ query: '(min-width: 1024px)' })

  return (<>
  
    {/* left container */}
    {showProductBundles && isScreenLg && <> 
      <div className="flex flex-grow h-full relative bg-white">
        <div id="bundles" className="absolute inset-y-0 right-0 left-auto flex flex-col overflow-y-auto scrollbar-hide max-w-xl">
          <ProductBundles />
        </div>
      </div>
    </>}
    
    {/* center container */}
    <VideoWrapper id="livestream">
      <EventPage />
      <ReactionOverlay /> 
      {(!isScreenLg && showChat) && <> 
        <div id="chat" className={'absolute inset-x-0 bottom-0 top-auto flex h-1/2 flex-col ' + (isReplay ? 'mb-12' : '')}>
          <UserChat />
        </div>
      </>}
      <UIButtons />
      <ProductPreviewContainer />
      <ProductBundlesBottomSheet />
      <ProductModal />
    </VideoWrapper>

    {/* right container */}
    {(showChat && isScreenLg) && <>
      <div className='flex flex-grow h-full relative'>
        <div id="chat" className="absolute inset-0 flex flex-col bg-white">
          <UserChat />
        </div>
      </div>
    </>}
  </>)
}

export default Livestream
