import { useMemo, useState } from 'react'
import StreamContext from './StreamContext'

const StreamContextProvider = ({ children }) => {

  const [userHasSkipped, setUserHasSkipped] = useState(false)
  const [videoHasEnded, setVideoHasEnded] = useState(false)

  const streamContext = useMemo(() => ({
    setUserHasSkipped,
    setVideoHasEnded,
    userHasSkipped,
    videoHasEnded,
  }), [
    setUserHasSkipped,
    setVideoHasEnded,
    userHasSkipped,
    videoHasEnded,
  ])

  return <StreamContext.Provider value={streamContext}>
    {children}
  </StreamContext.Provider>

}

export default StreamContextProvider
