import Livestream from './Livestream'
import useEventId from '../events/useEventId'

const LivestreamWidget = () => {

  useEventId()

  return <div className="flex flex-grow">
    <Livestream />
  </div>
}

export default LivestreamWidget