import { useContext } from 'react'
import FeatureContext from '../features/FeatureContext'
import Reaction from './Reaction'
import ReactionContext from './ReactionContext'

const ReactionOverlay = () => {

  const { showReactions } = useContext(FeatureContext)

  if (!showReactions) return null

  return <div id="reactions" className="absolute inset-y-0 right-0 w-1/3 pointer-events-none">
    <ReactionContext.Consumer>
      {(reactionContext) => <>
        {reactionContext?.reactions.map(reaction => {
          return <Reaction key={reaction.id} reaction={reaction} />
        })}
      </>}
    </ReactionContext.Consumer>
  </div>
}

export default ReactionOverlay