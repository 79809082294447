import { DELETED, USER_MUTED } from './messageVisibilityStatus'

export const isMessageAnswer = (message) => {
  if (!message) return false
  const { parentId } = message
  return !!parentId
}

export const isMessageVisible = (message, authToken) => {
  if (!message) return false
  const { visibilityStatus } = message
  const isVisible = visibilityStatus !== DELETED && visibilityStatus !== USER_MUTED
  return authToken || isVisible
}

export const isMessageFromModerator = (message) => {
  if (!message) return false
  const { fromModerator } = message
  return fromModerator === '1' || fromModerator === 1
}

export const addMessages = (messages, newMessages) => {
  const messageIds = new Set(messages.map(message => message.messageId))
  return messages.concat(newMessages?.filter(message => !messageIds.has(message.messageId)) || [])
}

export const updateMessage = (messages, updatedMessage) => {
  if (!updateMessage) return messages
  return messages?.map(message => message.messageId === updatedMessage.messageId ? updatedMessage : message)
}

export const newestMessage = (messages) => {
  if (!messages?.length) return null
  return messages.map(message => {
    message.date = new Date(message.timestamp)
    return message
  }).reduce((acc, message) => {
    if (acc.date > message.date) return acc
    return message
  }, messages[0].date)
}

export const oldestMessage = (messages) => {
  if (!messages?.length) return null
  return messages.map(message => {
    message.date = new Date(message.timestamp)
    return message
  }).reduce((acc, message) => {
    if (acc.date < message.date) return acc
    return message
  }, messages[0].date)
}


/**
 * search the message for links and replace them with <a> tags
 * @param {string} message 
 * @returns 
 */
export const formatMessageLinks = (message) => {
  const urlRegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
  return message.split(' ')
    .map((part, i) => urlRegExp.test(part) ? <span key={`${part}-${i}`}><a href={part} className="underline underline-offset-2" target="_blank" rel="noopener noreferrer">{part}</a>&nbsp;</span>  : part + ' ')
}
