import { useContext, useEffect, useState } from 'react'
import { getUserOrNull } from '../../util/usernameStorageConnector'
import * as actions from '../../util/connection/actions'
import EventContext from '../events/EventContext'
import UserContext from './UserContext'
import ConnectionHandler from '../../util/connection/ConnectionHandler'
import { sendUpdateUsernameCommand } from '../../util/connection/viewerActionSender'

const UserContextProvider = ({ children }) => {

  const { eventId, lastMessage, webSocket } = useContext(EventContext)
  const [user, setUser] = useState(null)

  useEffect(() => {
    setUser(getUserOrNull(eventId))
  }, [eventId])

  useEffect(() => {
    if (lastMessage === null || user === null) return;
    ConnectionHandler(lastMessage, actions.ACTION_INIT, () => {
      sendUpdateUsernameCommand(webSocket, user);
    });
  }, [webSocket, lastMessage, user]);

  return <UserContext.Provider value={[user, setUser]}>
    {children}
  </UserContext.Provider>

}

export default UserContextProvider
