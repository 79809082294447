import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import handleTimedMetadata from '../events/handleTimedMetadata'
import useTimedMetadata from '../events/useTimedMetadata'
import ReactionContext from './ReactionContext'
import emoticons from '../../data/emoticons'

const ReactionContextProvider = ({ children }) => {
  
  const reactionId = useRef(0) // the id, used for UI related reaction unification
  const [timedMetadata] = useTimedMetadata()
  const [reactions, setReactions] = useState([])

  const addReaction = useCallback((emoji) => {
    setReactions(reactions => {
      const currentTime = new Date()
      reactionId.current++
      reactions.push({
        id: reactionId.current,
        duration: 400,
        offsetX: Math.round(100 * Math.random()),
        time: currentTime,
        emoji
      })
      const newReactions = reactions.filter(reaction => {
        return (currentTime.getTime() - reaction.time.getTime()) < 3000
      })
      return newReactions
    })
  }, [])

  const reactionContext = useMemo(() => {
    return {
      addReaction,
      reactions,
    }
  }, [addReaction, reactions])

  useEffect(() => {
    handleTimedMetadata(timedMetadata, 'reactions', (reactions) => {
      if (reactions.length === 0) return
      const timeMillis = 3500 / reactions.length
      let timeOffset = 0
      reactions.forEach((reaction) => {
        const emoji = emoticons[reaction]
        timeOffset += timeMillis * Math.random() * 0.75 + 0.75
        setTimeout(() => addReaction(emoji), timeOffset)
      })
    })
  }, [addReaction, timedMetadata])

  return <ReactionContext.Provider value={reactionContext}>
    {children}
  </ReactionContext.Provider>

}
export default ReactionContextProvider