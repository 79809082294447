import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import EventContext from '../events/EventContext'
import handleTimedMetadata from '../events/handleTimedMetadata'
import useTimedMetadata from '../events/useTimedMetadata'
import ProductContext from './ProductContext'

const ProductContextProvider = ({ children }) => {

  const { eventOptions, metadata } = useContext(EventContext)
  const [timedMetadata] = useTimedMetadata()
  const [activeBundle, setActiveBundle] = useState(null)
  const [selectProduct, setSelectedProduct] = useState(null)
  const [showProductModal, setShowProductModal] = useState(false)
  const [showProductBundles, setShowProductBundles] = useState(false)
  
  const enablePreview = useMemo(() => eventOptions.includes('enable-preview'), [eventOptions])
  const activeBundleId = useMemo(() => activeBundle ? activeBundle.uid : null, [activeBundle])
  const productBundles = useMemo(() => metadata?.bundles || [], [metadata])

  useEffect(() => {
    handleTimedMetadata(timedMetadata, 'items', (items) => {
      if (items.length === 0) {
        setActiveBundle(null)
        return
      }
      const bundleId = items[0]
      if (bundleId === activeBundleId) return
      const productBundle = productBundles?.find(bundle => bundle.uid === bundleId)
      setActiveBundle(productBundle)
    })
  }, [productBundles, timedMetadata, activeBundleId])

  const getBundle = useCallback((bundleId) => {
    bundleId = parseInt(bundleId)
    return productBundles?.find(bundle => bundle.uid === bundleId)
  }, [productBundles])

  const onClickProduct = useCallback((product) => {
    setSelectedProduct(product)
    setShowProductModal(enablePreview)
  }, [enablePreview])

  const onCloseProductModal = () => {
    setShowProductModal(false)
  }

  const onOpenProductBundles = () => {
    setShowProductBundles(true)
  }

  const onCloseProductBundles = () => {
    setShowProductBundles(false)
  }

  const productContext = useMemo(() => {
    return {
      activeBundle,
      activeBundleId,
      getBundle,
      onClickProduct,
      onCloseProductBundles,
      onCloseProductModal,
      onOpenProductBundles,
      productBundles,
      showProductModal,
      selectProduct,
      showProductBundles,
    }
  }, [
    activeBundle,
    activeBundleId,
    getBundle,
    onClickProduct,
    productBundles,
    selectProduct,
    showProductModal,
    showProductBundles,
  ])

  return <ProductContext.Provider value={productContext}>
    {children}
  </ProductContext.Provider>  

}

export default ProductContextProvider