export const ConnectionHandler = (message, action, handlerFunction, errorFunction = () => { }) => {
  if (message === null) return
  const payload = JSON.parse(message.data)
  const { action: messageAction } = payload
  if (messageAction !== action) return
  if (!payload) return
  const { body } = payload
  let data = null
  try {
    let parsedBody = JSON.parse(body)
    data = parsedBody
  } catch (e) {
    data = { errorMessage: body }
    // console.error('could not parse body: ', body)
    // TODO we want the default exception handler also to return a parseable json string
  }
  if (!data) return
  const { statusCode } = payload
  if (statusCode < 400) {
    const { RequestId } = data
    if (RequestId) return // it is a request with a given id, not a general message
    handlerFunction(data)
  }
  if (statusCode >= 400) errorFunction(data)
}

export default ConnectionHandler
