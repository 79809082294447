/**
 * The VisibilityStatus value if the actual value is unknown or not set.
 * This should never be set.
 */
export const UNKNOWN = '0'

/**
 * The default value for the VisibilityStatus flag.
 * All visible messages should use this value.
 */
export const VISIBLE = '1'

/**
 * The VisibilityStatus flag value for deleted messages.
 * Messages flagged as this should/will not be rendered in the frontend to the general viewer.
 */
export const DELETED = '2'

/**
 * The VisibilityStatus flag value that a message will have that is sent by a banned/muted user.
 * Messages flagged as this should/will not be rendered in the frontend to the general viewer.
 */
export const USER_MUTED = '3'