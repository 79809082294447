import { useCallback, useContext, useEffect, useState } from 'react'
import ConnectionHandler from '../../util/connection/ConnectionHandler'
import { getBannedUsers } from '../../util/connection/viewerActionSender'
import EventContext from '../events/EventContext'
import ManagementContext from '../management/ManagementContext'
import * as actions from '../../util/connection/actions'
import UserList from './UserList'

const BannedUsers = () => {

  const { authToken } = useContext(ManagementContext)
  const { webSocket, lastMessage, socketOpen, eventId, isInit } = useContext(EventContext)
  const [bannedUsers, setBannedUsers] = useState([])

  const fetchBannedUsers = useCallback(() => {
    if (!socketOpen) return
    getBannedUsers(webSocket, authToken, eventId)
  }, [authToken, webSocket, socketOpen, eventId])

  useEffect(() => {
    if (!isInit) return
    fetchBannedUsers()
  }, [fetchBannedUsers, isInit])

  useEffect(() => {
    if (!socketOpen) return
    ConnectionHandler(lastMessage, actions.ACTION_LIST_BANNED_USERS, (data) => {
      const { Items: bannedUsers } = data
      console.log('BANNED USERS', bannedUsers)
      setBannedUsers(bannedUsers?.map(dynamoUser => ({
        id: dynamoUser?.UserId.S,
        displayName: dynamoUser?.DisplayName.S,
        isActive: dynamoUser?.IsInactive.N === '0',
      })).filter(user => user.isActive))
    })
  }, [webSocket, lastMessage, socketOpen])

  useEffect(() => {
    ConnectionHandler(lastMessage, actions.ACTION_BAN_USER, () => {
      fetchBannedUsers()
    })
    ConnectionHandler(lastMessage, actions.ACTION_UNBAN_USER, () => {
      fetchBannedUsers()
    })
  }, [lastMessage, fetchBannedUsers])

  if (bannedUsers.length === 0) return null

  return <div className="bg-zinc-800">
    <span className="p-2 font-bold text-white">Banned users</span>
    <UserList users={bannedUsers} />
  </div>

}

export default BannedUsers
